import React, { useEffect, useState }  from 'react';
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Tooltip as Mensaje } from "react-tooltip";
import { cantidadDeportistas } from '../controllers/configuracionFirebase';
import { coloresFechaCierre, time, timeLocal } from '../controllers/tiempoLimiteInscripcion';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Mapa from './Mapa'
import { Message } from 'primereact/message';
import Conctatenos from './Conctatenos';
import Swal from 'sweetalert2';
import CountUp from './CountUp';
import Marquee from './Marquee';

const Torneos = ({
  setIdTorneoParticipando,
  setParticipando,
  torneos,
  setTorneoEspecifico,
  usuario,
  setVisible,
  fechaGoogle}) => {

  const [ cantNadadores, setCantNadadores ] = useState([]);
  const [ dialogMapa, setDialogMapa ] = useState(false);
  const [ dialogChampions, setDialogChampions ] = useState(false);
  const [ mostrarMensaje, setMostrarMensaje ] = useState(false);
  const [ tituloEvento, setTituloEvento ] = useState('');
  const [ detallesMapa, setDetallesMapa ] = useState({distancia: null, tiempo: null});
  const [ direccion, setDireccion ] = useState('');

  function participar(torneo){
    setTorneoEspecifico(torneo);
    setIdTorneoParticipando(torneo.id);
    setParticipando(true);
    setVisible(true);
  }

  function irAlMapa(title, address){
    setDialogMapa(true);
    setTituloEvento(title);
    setDireccion(address)
  }

  const content = (
    <div className="d-flex align-items-center">
        <img alt="logo" src={require("../img/img-logo-swimmingt.webp")} width="50" />
        <div className="ms-2 text-danger">Para ver más detalles, por favor active la función de ubicación en su navegador.</div>
    </div>
  );

  const contentTimeCierre = (torneo, indexTorneo) => {
    return <div className="d-flex align-items-center">
        <div className={`ms-2`}>
          {!torneo.inscripcionesCerradas ? `Inscripciones ${torneo.fechaInscripciones}` : 'Inscripciones Cerradas'} <br />
          <div id={`contenedor-fecha${indexTorneo}`}>
              <span style={{textShadow: '0 0 1px black'}}>Faltan </span>
              <span id={`dia${indexTorneo}`} style={{textShadow: '0 0 1px black'}}>00</span>
              <span id={`dia-hora${indexTorneo}`} style={{textShadow: '0 0 1px black'}}></span>
            </div>
          </div>
    </div>
  };

  const contentDetallesMapa = (
    <div className="d-flex align-items-center">
        <img alt="logo" src={require("../img/img-logo-swimmingt.webp")} width="50" />
        <div className="ms-2 text-danger">{detallesMapa.distancia && detallesMapa.tiempo ? `Estás a ${detallesMapa.distancia} de distancia, llegando en ${detallesMapa.tiempo}.` : ''}</div>
    </div>
  );

  function mostrarMasInformacion(torneo){
    const alerta = Swal.mixin({
      showConfirmButton: false,
      showCancelButton: true
    })
    alerta.fire({
      title: torneo.titulo,
      html: `<table class="table table-bordered table-striped table-hover">
        <tr class="table-dark">
          <th colspan="3">Fechas</th>
        </tr>
        <tr class="table-dark">
          <th>Inicio</th>
          <th>Fin</th>
          <th>Cierre Inscrip.</th>
        </tr>
        <tr class="table-light">
          <td>${torneo.fechaTorneo}</td>
          <td>${torneo.fechaTorneoF}</td>
          <td>${torneo.fechaInscripciones}</td>
        </tr>
        <tr class="table-dark">
          <th>$ Club</th>
          <th colspan="2">$ Deportista</th>
        </tr>
        <tr class="table-light">
          <td>$${parseInt(torneo.valorClub).toLocaleString()}</td>
          <td colspan="2">$${parseInt(torneo.valorDeportista).toLocaleString()}</td>
        </tr>
        <tr class="table-dark">
          <th>Ciudad</th>
          <th colspan="2">Dirección</th>
        </tr>
        <tr class="table-light">
          <td>${torneo.ciudad}</td>
          <td colspan="2">${torneo.direccion}</td>
        </tr>
        <tr class="table-dark">
          <th colspan="3">Piscina</th>
        </tr>
        <tr class="table-dark">
          <th>Carriles</th>
          <th colspan="2">Distancia</th>
        </tr>
        <tr class="table-light">
          <td>${torneo.carril}</td>
          <td colspan="2">${torneo.piscina !== 'ninguno' ? torneo.piscina + ' Metros' : ''}</td>
        </tr>
      </table>`,
      cancelButtonText: 'Salir',
    })
  }

  useEffect(() => {
    const aux = [];

    async function obtenerCantidadesDeportistas() {
      for (const tor of torneos) {
        const cant = await cantidadDeportistas(tor.id);
        aux.push(cant);
      }
      setCantNadadores(aux);
    }

    obtenerCantidadesDeportistas();
  }, [torneos, setCantNadadores])

  useEffect(()=>{
    torneos.forEach((tor, indexTor)=>{
      time(tor.fechaInscripciones, tor.id, indexTor, fechaGoogle);
      timeLocal(tor.fechaInscripciones, indexTor);
    })
  },[torneos, fechaGoogle])

  return (
    <>
      <div className='d-flex flex-column' style={{height: '88vh'}}>
        {torneos.length !== 0 ? (
          <div className='w-100 m-auto flex-fill row row-cols-1 row-cols-sm-2 row-cols-md-4 justify-content-center align-items-start'>
            {torneos.map((torneo, indexTorneo) => {
                return (
                  <motion.div
                    className={`position-relative card m-2 pt-2 col shadow card-torneo ${torneo.inscripcionesCerradas ? 'animacion-rojo' : ''}`}
                    style={{ width: "22rem" }}
                    key={indexTorneo}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: `0.${indexTorneo}5` }}
                    id={`card-torneo${indexTorneo}`}
                  >
                    <span className='linea linea-left'></span>
                    <span className='linea linea-right'></span>
                    <span className='linea linea-top'></span>
                    <span className='linea linea-bottom'></span>
                    <ul className="circles-card-torneo">
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                    <div className="d-flex justify-content-between" style={{zIndex: 1}}>
                      <div
                        className="d-flex align-items-start position-relative" id={`cantNadadores${indexTorneo}`}
                      >
                        <motion.p
                          className='mb-0 fs-5 text-muted'
                          style={{fontFamily: "Calistoga" }}
                        >
                          <CountUp
                            from={0}
                            to={parseInt(cantNadadores[indexTorneo] || 0)}
                            separator=","
                            direction="up"
                            duration={1}
                            className=""
                          />
                        </motion.p>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className='text-muted me-1' style={{fontFamily: 'Calistoga'}} id={`contenedor-diaLocal${indexTorneo}`}>
                          <span className='fs-5' id={`diaLocal${indexTorneo}`}>00</span><span className='fs-5'>D : </span>
                          <span className='fs-5' id={`horaLocal${indexTorneo}`}>00</span><span className='fs-5'>H : </span>
                          <span className='fs-5' id={`minutoLocal${indexTorneo}`}>00</span><span className='fs-5'>M : </span>
                          <span className='fs-5' id={`segundoLocal${indexTorneo}`}>00</span><span className='fs-5'>S</span> 
                        </div>
                        <div id={`informacion${indexTorneo}`}>
                          <i className='pi pi-book text-primary fs-5' style={{cursor: 'pointer'}} onClick={() => mostrarMasInformacion(torneo)}></i>
                        </div>
                      </div>
                    </div>
                    <Message
                      style={{
                          border: 'solid #696cff',
                          borderWidth: '0 0 0 6px',
                          color: '#696cff',
                          position: 'relative'
                      }}
                      id={`messageFechaCierre${indexTorneo}`}
                      className={`justify-content-center p-1`}
                      severity={coloresFechaCierre(torneo.fechaInscripciones)}
                      content={contentTimeCierre(torneo, indexTorneo)}
                    />
                    <div style={{width: '100%', position: 'relative'}}>
                      <Marquee
                        items={[torneo.titulo, torneo.titulo, torneo.titulo, torneo.titulo, torneo.titulo, torneo.titulo, torneo.titulo]}
                        pauseOnHover={true}
                        size={`clamp(8rem, 100%, 25rem)`}
                        duration='80s'
                        bgColor='#060606'
                        bgAccentColor='#111111'
                      />  
                    </div>
                    <Mensaje
                      anchorId={`cantNadadores${indexTorneo}`}
                      place="bottom"
                      content={`Inscritos`}
                      className="bg-primary shadow"
                      style={{ zIndex: 1}}
                    />
                    <Mensaje
                      anchorId={`informacion${indexTorneo}`}
                      place="bottom"
                      content={`Información del evento`}
                      className="bg-primary shadow"
                      style={{ zIndex: 1}}
                    />
                    {torneo.image !== "" ? (
                    <img
                      src={torneo.image}
                      className="card-img-top w-100 position-relative mb-1 rounded"
                      alt="imagen del torneo"
                      style={{ filter: "drop-shadow(-1px 1px 2px black)"}}
                    />
                    ) : (
                      <img
                        src={require("../img/img_logo.png")}
                        className="card-img-top m-auto position-relative"
                        alt="imagen del torneo"
                        style={{ width: "200px", filter: "drop-shadow(-1px 1px 2px black)"}}
                      />
                    )}
                    <div className="card-body p-0 text-center position-relative">
                      {torneo.convocatoria !== '' ? (
                        <>
                          <Link to={torneo.convocatoria} target='_blank'>
                            <i className='pi pi-file-pdf text-danger btn btn-light mb-2 me-2 fs-4' id={`conv${indexTorneo}`}></i>
                          </Link>
                        </>
                      ):(<></>)}
                      <Link to="/inscripciones" onClick={() => participar(torneo)}>
                        <Button type='button' icon='pi pi-users' className='btn btn-success mb-2' label="¡Quiero Participar!" style={{borderRadius: '10px'}}>
                        </Button>
                      </Link>
                      <i type='button' id={`ubi${indexTorneo}`} className='pi pi-map-marker btn btn-light mb-2 ms-2 p-overlay-badge fs-4 position-relative' style={{borderRadius: '10px', color: 'rgb(184,135,0)'}} onClick={() => irAlMapa(torneo.titulo, torneo.direccion)}>
                      </i>
                      <Mensaje
                        anchorId={`ubi${indexTorneo}`}
                        place="bottom"
                        content={`Ubicación`}
                        className="bg-primary shadow"
                        style={{ zIndex: 1}}
                      />
                      <Mensaje
                        anchorId={`conv${indexTorneo}`}
                        place="bottom"
                        content={`Convocatoria`}
                        className="bg-primary shadow"
                        style={{ zIndex: 1}}
                      />
                    </div>
                  </motion.div>
                );
              })
            }
          </div>
        ):(
          <motion.div className='mt-2 pb-2 m-auto bg-light shadow' style={{borderRadius: '10px', maxWidth: '850px'}}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1}}
          >
            <img className='w-50' src={require('../img/img-logo-swimmingt.webp')} alt="Logo prowebsports" />
            <h1>¡BIENVENIDO!</h1>
            <p>No tenemos torneos registrados aún.</p>
          </motion.div>
        )}
        <Conctatenos/>
      </div>
      <Dialog visible={dialogMapa} style={{ width: '600px', height: '600px' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={`${tituloEvento}`} modal className="p-fluid" onHide={() => setDialogMapa(false)}>
        {mostrarMensaje ? (
        <Message
          style={{
              border: 'solid #696cff',
              borderWidth: '0 0 0 6px',
              color: '#696cff'
          }}
          className="border-danger justify-content-center mb-1"
          severity="error"
          content={content}
        />):(
          <Message
          style={{
              border: 'solid #696cff',
              borderWidth: '0 0 0 6px',
              color: '#696cff'
          }}
          className="border-success justify-content-center mb-1"
          severity="success"
          content={contentDetallesMapa}
          />
        )}
        {<Mapa
        direccion={direccion} 
        setMostrarMensaje={setMostrarMensaje} 
        tituloEvento={tituloEvento}
        setDetallesMapa={setDetallesMapa}
        usuario={usuario}/>}
      </Dialog>
      <Dialog visible={dialogChampions} style={{ width: '50em', height: '2397px' }} breakpoints={{ '700px': '100%' }} header="Encuesta PROWEBSPORTS CHAMPIONS" modal className="p-fluid" onHide={() => setDialogChampions(false)}>
        <div className="field">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe5SsAEAHqX1_MeGT1cPRyXGzNJGO7fDIW4e9LfWeCzjQgw-Q/viewform?embedded=true" width="100%" height="2550" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
        </div>
      </Dialog>
    </>
  )
}

export default Torneos;