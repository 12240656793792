import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useLocation, useNavigate } from "react-router-dom";
import '../styles/Panel.css';
import InscripcionNadadores from "./InscripcionNadadores";
import Tiempos from "./Tiempos";
import Planilla from "./Planilla";
import Consolidado from "./Consolidado";
import GanadorTrofeo from "./GanadorTrofeo";
import Autorizaciones from "./Autorizaciones";
import { cerrarSesion, traerTodasTablasPlanilla, cancelarSuscripcionConsolidado, cancelarNombresAutorizados, cancelarAutorizaciones, cancelarTrofeo, cancelarTiemposResultados, traerPruebasParaRegistroJPC, eliminarFotoPerfil, subirFotoPerfil, updateDatos, cancelarSuscripcionNadadores, cancelarSuscripcionTraerClubes, cancelarNombresListado, traerPrecios, traerNFactura, traerAfiliados, registrarUsuario, cancelarCategoria, traerListadoCategoria, requestNotificationPermission, traerRecords, traerTorneosTemporales } from "../controllers/configuracionFirebase";
import ResultadosTiempos from "./ResultadosTiempos";
import CrearTorneo from "./CrearTorneo";
import Pruebas from "./Pruebas";
import Torneos from "./Torneos";
import { completarPruebas } from "../controllers/completarPruebas";
import Swal from "sweetalert2";
import Ranking from "./Ranking";
import toast from "react-hot-toast";
import { AiFillEyeInvisible } from "react-icons/ai";
import Afiliacion from "./Afiliacion";
import Rifa from "./Rifa";
import { Tag } from "primereact/tag";
import SorteoInterno from "./SorteoInterno";
import { anioAutor, reloj } from "../controllers/tiempoLimiteInscripcion";
import { Sidebar } from 'primereact/sidebar';
import { Avatar } from 'primereact/avatar';
import { Dialog } from "primereact/dialog";
import { generarQrCode, generarQrCodeMayorCalidad } from "../controllers/qrCode";
import PlanillaInvitado from "./PlanillaInvitado";
import { datosAdmin, departamentosColombia } from "../controllers/administrador";
import ControlClubs from "./ControlClubs";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { loadGoogleMapsAPI } from "../controllers/apiGoogleMaps";

const Panel = ({ usuario, setUsuario, fechaGoogle }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const [ torneos, setTorneos ] = useState([]);
  const [ pruebasCompletas, setPruebasCompletas ] = useState([]);
  const [ listPruebas, setListPruebas ] = useState([]);
  const [ planilla, setPlanilla ] = useState([]);
  const [ resultados, setResultados ] = useState([]);
  const [ imgPerfil, setImgPerfil ] = useState();
  const [ idTorneoParticipando, setIdTorneoParticipando ] = useState('');
  const [ torneoEspecifico, setTorneoEspecifico ] = useState([]);
  const [ participando, setParticipando ] = useState(false);
  const [ afiliados, setAfiliados ] = useState([]);
  const [ preciosEstablecidos, setPreciosEstablecidos ] = useState();
  const [ numeroFactura, setNumeroFactura ] = useState('ninguna');
  const [ visible, setVisible ] = useState(false);
  const [ dialogQrCode, setDialogQrCode ] = useState(false);
  const [ dialogCrearCuenta, setDialogCrearCuenta ] = useState(false);
  const [ contrasena, setContrasena ] = useState('');
  const [ validarContrasena, setValidarContrasena ] = useState('');
  const [ fechaRegistroProgramacion, setFechaRegistroProgramacion ] = useState('');
  const [ listadoCategorias, setListadoCategorias ] = useState([]);
  const [ contadorLlamadoApi, setContadorLlamadoApi ] = useState(0);
  const [ recordsMundial, setRecordsMundial ] = useState();
  const [ recordsNacional, setRecordsNacional ] = useState();

  /* - el estado visibilityActual nos ayudara a poner la contraseña visible o no. */
  const [visibilityActual, setVisibilityActual] = useState(true);

  /* - el estado visibilityNuevo nos ayudara a poner la contraseña visible o no. */
  const [visibilityNuevo, setVisibilityNuevo] = useState(true);

  /* la funcion clickVisibilityActual nos ayudara con la funcionalidad de la visibilidad de la contraseña. */
  function clickVisibilityActual() {
    setVisibilityActual(!visibilityActual);
  }

  /* la funcion clickVisibilityNuevo nos ayudara con la funcionalidad de la visibilidad de la contraseña. */
  function clickVisibilityNuevo() {
    setVisibilityNuevo(!visibilityNuevo);
  }

  /* La funcion onSubmit nos ayudara a traer los nuevos datos que se van actualizar en configuración. */
  function onSubmitCambioContrasena(e) {
    e.preventDefault();

    const nombre = e.target.nombre.value;
    const email = e.target.email.value;
    const passwordActual = e.target.passwordActual.value;
    const passwordNuevo = e.target.passwordNuevo.value;

    const nuevoDatos = {
      nombre,
      passwordActual,
      passwordNuevo,
      email,
    };

    updateDatos(nuevoDatos);
  }

  /* la funcion changeFile nos ayudara a subir una foto a firebase */
  function changeFile(e) {
    const img = e.target.files[0];
    if(!img.name.endsWith(".pdf") && !img.name.endsWith(".docx") && !img.name.endsWith(".pptx")){
      subirFotoPerfil(img, usuario.id, setImgPerfil, usuario);
    }else{
      toast.error('Lo sentimos, la foto que estás intentando subir no es válida. Por favor, asegúrate de seleccionar una foto compatible y vuelve a intentarlo. ¡Gracias!');
    }
  }

  function eliminarFoto() {
    if (imgPerfil) {
      const buscarNombreImg = imgPerfil.split('?')[0];
      eliminarFotoPerfil(setImgPerfil, decodeURIComponent(buscarNombreImg.split('/').pop()), usuario);
    } else {
      toast.error(
        "Esta foto es la que viene por defecto, lo que no se puede eliminar."
      );
    }
  }

  function abrirQrCode(){
    setDialogQrCode(true);
    setTimeout(function() {
      generarQrCode().append(document.getElementById('qr-code'));
    }, 100);
  }

  function bandera(departamento){
    const buscarBandera = departamentosColombia.filter((depBan) => {return depBan.dep === departamento});
    if(buscarBandera.length !== 0){
      return <div className="d-flex position-absolute top-0 end-0">
        <span title='Abreviatura' className="me-1" style={{fontSize: "14px", fontWeight: "bold"}}>{usuario.abreviatura}</span>
        <img title={departamento} style={{width: '30px', filter: "drop-shadow(-1px 1px 1px black)"}} src={buscarBandera[0].bandera} alt='Bandera'/>
      </div>
    }
  }

  function onSubmitCrearCuenta(e) {
    e.preventDefault();

    const nombreclub = usuario.nombreclub;
    const correo = e.target.email.value;
    const password = contrasena
    const passwordValidar = validarContrasena
    if(nombreclub){
      if(password === passwordValidar){
        registrarUsuario(correo, password, nombreclub);
        setContrasena('');
        setValidarContrasena('');
        e.target.reset();
      }else{
        toast.error('Las contraseñas no coinciden, por favor verifica.');
      }
    }else{
      toast.error('Para registrarse, es necesario elegir el nombre del club.');
    }
  }

  useEffect(() => {
    /* este condicional nos ayudara con el control del estado, asi podemos poner la img default o la del usuario. */
    function photoUsuario() {
      if (usuario.photo) {
        setImgPerfil(usuario.photo);
      } else {
        setImgPerfil();
      }
    }

     /* La funcion controlNavegacion nos ayudara a redirigir al usuario al inicio si intenta ingresar a rutas no autorizadas, esto una vez haya cargado todo. */
    function controlNavegacion() {
      let legal = true;
      if(usuario.nombreclub !== datosAdmin.nombre && usuario.nombreclub !== 'PROWEBSPORTS'){
        if ((location.pathname === '/programacion/pdfs' ||
        location.pathname === '/controlclubs' ||
        location.pathname === '/tiempos' ||
        location.pathname === '/autorizaciones' ||
        location.pathname === '/pruebas'  ||
        location.pathname === '/creartorneo/configurar' ||
        location.pathname === '/programacion' ||
        location.pathname === '/logistica' ||
        location.pathname === '/rifa' ||
        location.pathname === '/sorteointerno' ||
        location.pathname === '/creartorneo'
        )) {
          legal = false;
        }
      }
      if(!legal){
        const alerta = Swal.mixin({
          toast: true,
          timer: '20000',
          timerProgressBar: true
        })
        alerta.fire({
          html: `<div class="w-100 position-relative">
          <img style="width: 100px; position: absolute; top: 13px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
          <p style="z-index: 10; font-family: Poppins" class="mb-0">Club ${usuario.nombreclub}, estas intentando ingresar </br> ilegalmente a rutas no </br> autorizadas, 
          el equipo PROWEBSPORTS, monitorea el funcionamiento de las rutas no autorizadas, por favor te pedimos que utilices la plataforma correctamente.
          </div>`,
          position: 'top-end',
          confirmButtonText: 'Entendido',
          confirmButtonColor: 'blue',
          padding: 2
        })
        return navigate("/");
      }

      if(location.pathname === '/' 
        || location.pathname === '/creartorneo' 
        || location.pathname === '/autorizaciones'
        || location.pathname === '/ranking'
        || location.pathname === '/logistica'
        || location.pathname === '/creartorneo/configurar'
        || location.pathname === '/sorteointerno'
        || location.pathname === '/pruebas'){
          setParticipando(false);
      }else{
        setParticipando(true);
      }

      if((location.pathname === '/inscripciones'
        || location.pathname === '/controlclubs'
        || location.pathname === '/programacion/pdfs'
        || location.pathname === '/programacion'
        || location.pathname === '/programacioninvitado'
        || location.pathname === '/tiempos/pdfs'
        || location.pathname === '/resultadostiempos/pdfs'
        || location.pathname === '/tiempos'
        || location.pathname === '/trofeo'
        || location.pathname === '/estadisticascategorias'
        || location.pathname === '/rifa'
        || location.pathname === '/resultadostiempos')
        && !participando){
          return navigate("/");
      }

      if(location.pathname !== '/trofeo'){
        cancelarTrofeo();
      }

      if(location.pathname !== '/estadisticascategorias'){
        cancelarSuscripcionConsolidado();
      }

      if(location.pathname !== '/autorizaciones' &&
        location.pathname !== '/controlclubs' &&
        location.pathname !== '/resultadostiempos' &&
        location.pathname !== '/trofeo'){
        cancelarNombresAutorizados();
        cancelarAutorizaciones();
      }

      if(location.pathname !== '/resultadostiempos'){
        cancelarTiemposResultados();
      }

      if(location.pathname !== '/inscripciones'){
        cancelarSuscripcionNadadores();
      }

      if(location.pathname !== '/rifa' &&
      location.pathname !== '/controlclubs'){
        cancelarSuscripcionTraerClubes();
      }

      if(location.pathname !== '/pruebas' && 
        location.pathname !== '/creartorneo/configurar' &&
        location.pathname !== '/inscripciones' &&
        location.pathname !== '/estadisticascategorias' &&
        location.pathname !== '/tiempos' &&
        location.pathname !== '/resultadostiempos' &&
        location.pathname !== '/'){
        cancelarCategoria();
      }

      if(location.pathname !== '/'){
        cancelarNombresListado();
      }
    }
  
    photoUsuario();
    controlNavegacion();
    traerPruebasParaRegistroJPC(setListPruebas);
    setPruebasCompletas(completarPruebas(torneoEspecifico, listPruebas, listadoCategorias));
    traerTodasTablasPlanilla(torneoEspecifico.length !== 0 ? torneoEspecifico.id : [], setPlanilla, setFechaRegistroProgramacion);
    traerListadoCategoria(setListadoCategorias);
  }, [location, navigate, idTorneoParticipando, usuario])

  useEffect(() => {
    traerRecords(setRecordsMundial, setRecordsNacional);
    reloj();
    traerTorneosTemporales(setTorneos);
  }, []);

  useEffect(()=>{
    traerAfiliados(setAfiliados, preciosEstablecidos);
  },[preciosEstablecidos])

  useEffect(()=>{
    traerNFactura(setNumeroFactura);
  },[])
  
  useEffect(()=>{
    traerPrecios(setPreciosEstablecidos);
  },[])

  useEffect(() =>{
    function irAlMapa(){
      if(contadorLlamadoApi === 0){
        loadGoogleMapsAPI().then(()=>{
          setContadorLlamadoApi(contadorLlamadoApi + 1);
        })
      }
    }

    irAlMapa();

    requestNotificationPermission(usuario);
  },[])
  
  return (
    <>
      <div className="bg"></div>
      <div className="bg bg2"></div>
      <div className="bg bg3"></div>
      <nav className="navbar navbar-dark sticky-top bg-dark" aria-label="breadcrumb">
        <div className="container-fluid">
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <button 
                className="navbar-toggler"
                type="button"
                onClick={() => setVisible(true)}
              >
              <span className="navbar-toggler-icon"></span>
              </button>
              {usuario.nombreclub !== datosAdmin.nombre && usuario.nombreclub !== 'PROWEBSPORTS' ? (
              location.pathname === '/' ?(
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item active" aria-current="page">TORNEOS</li>
              </ol>
              ):(
              location.pathname === '/resultadostiempos' ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">RESULTADOS TIEMPOS</li>
              </ol>
              ):(
              location.pathname === `/resultadostiempos/pdfs` ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">PDF</li>
              </ol>
              ):(
              location.pathname === `/trofeo` ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item" aria-current="page"><Link to='/resultadostiempos' style={{color: 'white', textDecoration: 'none'}}>RESULTADOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">TROFEO</li>
              </ol>
              ):(
              location.pathname === `/estadisticascategorias` ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">ESTADISTICAS</li>
              </ol>
              ):(
              location.pathname === `/programacioninvitado` ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">PROGRAMACIÓN</li>
              </ol>
              ):(
              location.pathname === `/ranking` ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">RANKING</li>
              </ol>
              ):(
                <li></li>
              )
              ))))))):(
              <>{
              location.pathname === '/' ?(
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item active" aria-current="page">TORNEOS</li>
              </ol>
              ):(
              location.pathname === '/creartorneo' ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">CREAR TORNEO</li>
              </ol>
              ):(
              location.pathname === '/pruebas' ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">PRUEBAS</li>
              </ol>
              ):(
              location.pathname === '/inscripciones' ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">INSCRIPCIONES</li>
              </ol>
              ):(
              location.pathname === '/controlclubs' ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">CONTROL DE CLUBS</li>
              </ol>
              ):(
              location.pathname === '/creartorneo/configurar' ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item" aria-current="page"><Link to='/creartorneo' style={{color: 'white', textDecoration: 'none'}}>CREAR TORNEO</Link></li>
                <li className="breadcrumb-item active" aria-current="page">CONFIGURAR</li>
              </ol>
              ):(
              location.pathname === '/tiempos' ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">TIEMPOS</li>
              </ol>
              ):(
              location.pathname === '/resultadostiempos' ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item" aria-current="page"><Link to='/tiempos' style={{color: 'white', textDecoration: 'none'}}>TIEMPOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">RESULTADOS</li>
              </ol>
              ):(
              location.pathname === `/programacion/pdfs` ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item" aria-current="page"><Link to='/programacion' style={{color: 'white', textDecoration: 'none'}}>PROGRAMACIÓN</Link></li>
                <li className="breadcrumb-item active" aria-current="page">PDF</li>
              </ol>
              ):(
              location.pathname === `/trofeo` ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item" aria-current="page"><Link to='/tiempos' style={{color: 'white', textDecoration: 'none'}}>TIEMPOS</Link></li>
                <li className="breadcrumb-item" aria-current="page"><Link to='/resultadostiempos' style={{color: 'white', textDecoration: 'none'}}>RESULTADOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">TROFEO</li>
              </ol>
              ):(
              location.pathname === `/estadisticascategorias` ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item" aria-current="page"><Link to='/controlclubs' style={{color: 'white', textDecoration: 'none'}}>CONTROL DE CLUBS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">ESTADISTICAS</li>
              </ol>
              ):(
              location.pathname === `/programacion` ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">PROGRAMACIÓN</li>
              </ol>
              ):(
              location.pathname === `/programacioninvitado` ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">PROGRAMACIÓN</li>
              </ol>
              ):(
              location.pathname === `/autorizaciones` ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">AUTORIZACIONES</li>
              </ol>
              ):(
              location.pathname === `/resultadostiempos/pdfs` ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item" aria-current="page"><Link to='/tiempos' style={{color: 'white', textDecoration: 'none'}}>TIEMPOS</Link></li>
                <li className="breadcrumb-item" aria-current="page"><Link to='/resultadostiempos' style={{color: 'white', textDecoration: 'none'}}>RESULTADOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">PDF</li>
              </ol>
              ):(
              location.pathname === `/ranking` ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">RANKING</li>
              </ol>
              ):(
              location.pathname === `/logistica` ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">LOGISTICA</li>
              </ol>
              ):(
              location.pathname === `/rifa` ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">RIFA</li>
              </ol>
              ):(
              location.pathname === `/sorteointerno` ? (
              <ol className="breadcrumb mb-0 ps-3" style={{fontSize: '15px'}}>
                <li className="breadcrumb-item" aria-current="page"><Link to='/' style={{color: 'white', textDecoration: 'none'}}>TORNEOS</Link></li>
                <li className="breadcrumb-item active" aria-current="page">SORTEO INTERNO</li>
              </ol>
              ):(
                <li></li>
              )
              )
              )))))))))))))))))}
              </>
              )
            }
            </div>
            <span className="text-white" id="reloj"></span>
          </div>
        </div>
      </nav>
      <div className="card flex justify-content-center position-relative">
        <Sidebar visible={visible} position="left" onHide={() => setVisible(false)}>
          <h2 className="text-center position-absolute" style={{top: '18px'}}>¡Bienvenido!</h2>
          <Link className="position-absolute" to='https://www.prowebsports.com.co/inicio' target="_blank" style={{fontSize: '12px', textDecoration: 'none', top: '57px'}}>© 2023 - {anioAutor()} PROWEBSPORTS</Link>
          <div className="d-flex justify-content-center align-items-end">
          </div>
          <hr className="m-0" />
          <div className="d-flex justify-content-start align-items-center mt-1 position-relative">
            <label className="position-relative" title="Subir foto" htmlFor="foto" style={{cursor: 'pointer'}}>
              <Avatar className="flex align-items-center justify-content-center mr-2" image={usuario.photo} label={usuario.photo !== null ? '' : usuario.nombreclub[0]} size="xlarge"/>
              {usuario.photo ?(
                <label
                  className="position-absolute"
                  title="Eliminar foto"
                  htmlFor="f"
                  style={{ cursor: "pointer", width: "30px", top: '-4px', right: '-15px' }}
                  onClick={() => eliminarFoto()}
                >
                  <i className="pi pi-times-circle text-danger"></i>
                </label>
              ):(
                <></>
              )}
            </label>
            <span className="ms-1" style={{width: '178px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{usuario.nombreclub}</span>
            <input
              type="file"
              id="foto"
              onChange={changeFile}
              style={{ display: "none" }}
            />
            {bandera(usuario.departamento)}
            <span className="position-absolute end-0" style={{bottom: '-2px'}}>{usuario.nombreclub === datosAdmin.nombre || usuario.nombreclub === 'PROWEBSPORTS' ? <Tag icon='pi pi-user' severity='danger' value='ADMINISTRADOR/A' style={{padding: '0.1rem 0.3rem'}}></Tag> : <Tag icon='pi pi-user' severity='warning' value='INVITADO/A' style={{padding: '0.1rem 0.3rem'}}></Tag>}</span>
          </div>
          <div style={{width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '15px'}}>
            <span>{usuario.email}</span>
          </div>
          <hr className="m-0" />
          {usuario.nombreclub !== datosAdmin.nombre && usuario.nombreclub !== 'PROWEBSPORTS' ? (
            <ul className="navbar-nav justify-content-end flex-grow-1 text-dark">
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-th-large me-1 ms-1"></i>
                <Link to="/" className="w-100 nav-link" style={{fontFamily: 'Merriweather Sans'}}>
                  Torneos
                </Link>
              </li>
              <hr className="m-0"/>
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-chart-line me-1 ms-1"></i>
                <Link to="/ranking" className="w-100 nav-link" style={{fontFamily: 'Merriweather Sans'}}>
                  Ranking
                </Link>
              </li>
              <hr className="m-0"/>
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-th-large me-1 ms-1"></i>
                <Link
                  to='https://www.prowebsports.com.co' target="_blank"
                  className="w-100 nav-link"
                  style={{fontFamily: 'Merriweather Sans' }}
                >
                  Torneos Externos
                </Link>
              </li>
              <hr className="m-0"/>
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-qrcode me-1 ms-1"></i>
                <Link to={`${location.pathname}`} className={`w-100 nav-link d-flex align-items-center`}
                style={{fontFamily: 'Merriweather Sans'}} onClick={() => abrirQrCode()}
                >
                  QR Code
                </Link> 
              </li>
              <hr className="m-0"/>
              {participando ?(
                <>
                  <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                    <i className="pi pi-user-plus me-1 ms-1"></i>
                    <Link to="/inscripciones" className={`w-100 nav-link d-flex align-items-center ${!participando ? 'disabled' : 'resaltadoPorMomento'}`}
                    style={{fontFamily: 'Merriweather Sans'}}
                    >
                      Inscripciones
                    </Link>
                  </li>
                  <hr className="m-0"/>
                  <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                    <i className="pi pi-stopwatch me-1 ms-1"></i>
                    <Link to="/resultadostiempos" className={`w-100 nav-link d-flex align-items-center ${!participando ? 'disabled' : 'resaltadoPorMomento'}`}
                    style={{fontFamily: 'Merriweather Sans'}}>
                      Resultados
                    </Link>
                  </li>
                  <hr className="m-0"/>
                  <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                    <i className="pi pi-trophy me-1 ms-1"></i>
                    <Link to="/trofeo" className={`w-100 nav-link d-flex align-items-center ${!participando ? 'disabled' : 'resaltadoPorMomento'}`}
                    style={{fontFamily: 'Merriweather Sans'}}
                    >
                      Puntuaciones
                    </Link>
                  </li>
                  <hr className="m-0"/>
                  <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                    <i className="pi pi-chart-bar me-1 ms-1"></i>
                    <Link to="/estadisticascategorias" className={`w-100 nav-link d-flex align-items-center ${!participando ? 'disabled' : 'resaltadoPorMomento'}`}
                    style={{fontFamily: 'Merriweather Sans'}}
                    >
                      Estadísticas Categorías
                    </Link>
                  </li>
                  <hr className="m-0"/>
                  <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                    <i className="pi pi-file-edit me-1 ms-1"></i>
                    <Link to="/programacioninvitado" className={`w-100 nav-link d-flex align-items-center ${!participando ? 'disabled' : 'resaltadoPorMomento'}`}
                    style={{fontFamily: 'Merriweather Sans'}}
                    >
                      Programación
                    </Link>
                  </li>
                  <hr className="m-0"/>
                </>
              ):(
                <></>
              )}
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-plus-circle me-1 ms-1"></i>
                <Link className="w-100 nav-link d-flex align-items-center" onClick={() => setDialogCrearCuenta(true)}
                style={{fontFamily: 'Merriweather Sans'}}
                >
                  Crear Otra Cuenta
                </Link>
              </li>
              <hr className="m-0"/>
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-lock-open me-1 ms-1"></i>
                <Link className="w-100 nav-link" data-bs-toggle="modal" data-bs-target="#exampleModal"
                style={{fontFamily: 'Merriweather Sans'}}
                >
                  Cambiar Contraseña
                </Link>
              </li>
              <hr className="m-0"/>
              <li className="nav-item d-flex align-items-center" onClick={() => cerrarSesion()}>
                <i className="pi pi-sign-out me-1 ms-1 text-danger"></i>
                <Link
                  to="/"
                  className="w-100 nav-link"
                  style={{ color: "red" }}
                >
                  Cerrar Sesión
                </Link>
              </li>
              <hr className="m-0"/>
            </ul>
          ):(
            <ul className="navbar-nav justify-content-end flex-grow-1 text-dark">
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-th-large me-1 ms-1"></i>
                <Link to="/" className="w-100 nav-link" style={{fontFamily: 'Merriweather Sans'}}>
                  Torneos
                </Link>
              </li>
              <hr className="m-0"/>
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-plus-circle me-1 ms-1"></i>
                <Link to="/creartorneo" className="w-100 nav-link" style={{fontFamily: 'Merriweather Sans'}}
                >
                  Crear Torneo
                </Link>
              </li>
              <hr className="m-0"/>
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-chart-line me-1 ms-1"></i>
                <Link to="/ranking" className="w-100 nav-link" style={{fontFamily: 'Merriweather Sans'}}>
                  Ranking
                </Link>
              </li>
              <hr className="m-0"/>
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-plus-circle me-1 ms-1"></i>
                <Link to="/pruebas" className="w-100 nav-link" style={{fontFamily: 'Merriweather Sans'}}>
                  Registro Pruebas
                </Link>
              </li>
              <hr className="m-0"/>
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-calculator me-1 ms-1"></i>
                <Link to="/logistica" className={`w-100 nav-link d-flex align-items-center`}
                style={{fontFamily: 'Merriweather Sans'}}
                >
                  Logistica
                </Link>
              </li>
              <hr className="m-0"/>
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-th-large me-1 ms-1"></i>
                <Link
                  to='https://www.prowebsports.com.co' target="_blank"
                  className="w-100 nav-link"
                  style={{fontFamily: 'Merriweather Sans' }}
                >
                  Torneos Externos
                </Link>
              </li>
              <hr className="m-0"/>
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-qrcode me-1 ms-1"></i>
                <Link to={`${location.pathname}`} className={`w-100 nav-link d-flex align-items-center`}
                style={{fontFamily: 'Merriweather Sans'}} onClick={() => abrirQrCode()}
                >
                  QR Code
                </Link>
              </li>
              <hr className="m-0"/>
              {participando ?(
                <>
                  <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                    <i className="pi pi-user-plus me-1 ms-1"></i>
                    <Link to="/inscripciones" className={`w-100 nav-link d-flex align-items-center ${!participando ? 'disabled' : 'resaltadoPorMomento'}`}
                    style={{fontFamily: 'Merriweather Sans'}}
                    >
                      Inscripciones
                    </Link>
                  </li>
                  <hr className="m-0"/>
                  <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                    <i className="pi pi-book me-1 ms-1"></i>
                    <Link to="/controlclubs" className={`w-100 nav-link d-flex align-items-center ${!participando ? 'disabled' : 'resaltadoPorMomento'}`}
                    style={{fontFamily: 'Merriweather Sans'}}
                    >
                      Control De Clubs
                    </Link>
                  </li>
                  <hr className="m-0"/>
                  <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                    <i className="pi pi-stopwatch me-1 ms-1"></i>
                    <Link to="/tiempos" className={`w-100 nav-link d-flex align-items-center ${!participando ? 'disabled' : 'resaltadoPorMomento'}`}
                    style={{fontFamily: 'Merriweather Sans'}}>
                      Registrar Tiempos
                    </Link>
                  </li>
                  <hr className="m-0"/>
                  <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                    <i className="pi pi-stopwatch me-1 ms-1"></i>
                    <Link to="/resultadostiempos" className={`w-100 nav-link d-flex align-items-center ${!participando ? 'disabled' : 'resaltadoPorMomento'}`}
                    style={{fontFamily: 'Merriweather Sans'}}>
                      Resultados
                    </Link>
                  </li>
                  <hr className="m-0"/>
                  <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                    <i className="pi pi-trophy me-1 ms-1"></i>
                    <Link to="/trofeo" className={`w-100 nav-link d-flex align-items-center ${!participando ? 'disabled' : 'resaltadoPorMomento'}`}
                    style={{fontFamily: 'Merriweather Sans'}}
                    >
                      Puntuaciones
                    </Link>
                  </li>
                  <hr className="m-0"/>
                  <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                    <i className="pi pi-chart-bar me-1 ms-1"></i>
                    <Link to="/estadisticascategorias" className={`w-100 nav-link d-flex align-items-center ${!participando ? 'disabled' : 'resaltadoPorMomento'}`}
                    style={{fontFamily: 'Merriweather Sans'}}
                    >
                      Estadísticas Categorías
                    </Link>
                  </li>
                  <hr className="m-0"/>
                  {usuario.nombreclub === 'PROWEBSPORTS' ? (
                    <>
                      <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                        <i className="pi pi-file-edit me-1 ms-1"></i>
                        <Link to="/programacion" className={`w-100 nav-link d-flex align-items-center ${!participando ? 'disabled' : 'resaltadoPorMomento'}`}
                        style={{fontFamily: 'Merriweather Sans'}}
                        >
                          Programación
                        </Link>
                      </li>
                      <hr className="m-0"/>
                    </>
                  ):(
                    <></>
                  )}
                  {usuario.nombreclub !== 'PROWEBSPORTS' ? (
                    <>
                      <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                        <i className="pi pi-file-edit me-1 ms-1"></i>
                        <Link to="/programacioninvitado" className={`w-100 nav-link d-flex align-items-center ${!participando ? 'disabled' : 'resaltadoPorMomento'}`}
                        style={{fontFamily: 'Merriweather Sans'}}
                        >
                          Programación
                        </Link>
                      </li>
                      <hr className="m-0"/>
                    </>
                  ):(
                    <></>
                  )}
                  <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                    <i className="pi pi-gift me-1 ms-1"></i>
                    <Link to="/rifa" className={`w-100 nav-link d-flex align-items-center ${!participando ? 'disabled' : 'resaltadoPorMomento'}`}
                    style={{fontFamily: 'Merriweather Sans'}}
                    >
                      Rifa
                    </Link>
                  </li>
                  <hr className="m-0"/>
                </>
              ):(
                <></>
              )}
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-gift me-1 ms-1"></i>
                <Link to="/sorteointerno" className={`w-100 nav-link d-flex align-items-center`}
                style={{fontFamily: 'Merriweather Sans'}}
                >
                  Sorteo Interno
                </Link>
              </li>
              <hr className="m-0"/>
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-key me-1 ms-1"></i>
                <Link to="/autorizaciones" className={`w-100 nav-link`}
                style={{fontFamily: 'Merriweather Sans'}}
                >
                  Autorizaciones
                </Link>
              </li>
              <hr className="m-0"/>
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-plus-circle me-1 ms-1"></i>
                <Link className="w-100 nav-link d-flex align-items-center" onClick={() => setDialogCrearCuenta(true)}
                style={{fontFamily: 'Merriweather Sans'}}
                >
                  Crear Otra Cuenta
                </Link>
              </li>
              <hr className="m-0"/>
              <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
                <i className="pi pi-lock-open me-1 ms-1"></i>
                <Link className="w-100 nav-link" data-bs-toggle="modal" data-bs-target="#exampleModal"
                style={{fontFamily: 'Merriweather Sans'}}
                >
                  Cambiar Contraseña
                </Link>
              </li>
              <hr className="m-0"/>
              <li className="nav-item d-flex align-items-center" onClick={() => cerrarSesion()}>
                <i className="pi pi-sign-out me-1 ms-1 text-danger"></i>
                <Link
                  to="/"
                  className="w-100 nav-link"
                  style={{ color: "red" }}
                >
                  Cerrar Sesión
                </Link>
              </li>
              <hr className="m-0"/>
            </ul>
          )}
        </Sidebar>
      </div>
      <Routes>
        <Route
          path="/creartorneo/*"
          element={<CrearTorneo 
            usuario={usuario} 
            listPruebas={listPruebas}
            listadoCategorias={listadoCategorias}/>}
        />
        <Route
          path="/ranking"
          element={<Ranking/>}
        />
        <Route
          path="/logistica"
          element={<Afiliacion 
            afiliados={afiliados} 
            preciosEstablecidos={preciosEstablecidos}
            numeroFactura={numeroFactura}
            usuario={usuario}/>}
        />
        <Route
          path="/pruebas"
          element={<Pruebas 
            usuario={usuario} 
            listPruebas={listPruebas}
            listadoCategorias={listadoCategorias}/>}
        />
        <Route
          path="/autorizaciones"
          element={<Autorizaciones usuario={usuario}/>}
        />
        <Route
          path="/sorteointerno"
          element={<SorteoInterno />}
        />
        <Route
          path="/rifa"
          element={<Rifa idTorneoParticipando={idTorneoParticipando}/>}
        />
        <Route
          path="/programacioninvitado"
          element={<PlanillaInvitado idTorneoParticipando={idTorneoParticipando}
          pruebasCompletas={pruebasCompletas}
          planilla={planilla}
          torneoEspecifico={torneoEspecifico}
          usuario={usuario}
          fechaRegistroProgramacion={fechaRegistroProgramacion}
          recordsMundial={recordsMundial}
          recordsNacional={recordsNacional}/>}
        />
        <Route
          path="/programacion"
          element={<Planilla idTorneoParticipando={idTorneoParticipando}
          pruebasCompletas={pruebasCompletas}
          planilla={planilla}
          torneoEspecifico={torneoEspecifico}
          usuario={usuario}
          fechaRegistroProgramacion={fechaRegistroProgramacion}
          fechaGoogle={fechaGoogle}
          recordsMundial={recordsMundial}
          recordsNacional={recordsNacional}/>}
        />
        <Route
          path="/estadisticascategorias"
          element={<Consolidado 
            idTorneoParticipando={idTorneoParticipando}
            torneoEspecifico={torneoEspecifico}
            pruebasCompletas={pruebasCompletas}
            planilla={planilla}
            fechaRegistroProgramacion={fechaRegistroProgramacion}
            listadoCategorias={listadoCategorias}
            usuario={usuario}/>}
        />
        <Route
          path="/trofeo"
          element={<GanadorTrofeo 
            idTorneoParticipando={idTorneoParticipando}
            torneoEspecifico={torneoEspecifico}
            usuario={usuario}/>}
        />
        <Route
          path="/resultadostiempos"
          element={<ResultadosTiempos 
            infoUsuario={usuario} 
            resultados={resultados} 
            idTorneoParticipando={idTorneoParticipando} 
            setResultados={setResultados}
            torneoEspecifico={torneoEspecifico}
            recordsMundial={recordsMundial}
            recordsNacional={recordsNacional}
            listPruebas={listPruebas}
            listadoCategorias={listadoCategorias}/>}
        />
        <Route
          path="/tiempos"
          element={<Tiempos pruebasCompletas={pruebasCompletas} 
          idTorneoParticipando={idTorneoParticipando}
          torneoEspecifico={torneoEspecifico}
          planilla={planilla}
          usuario={usuario}
          listadoCategorias={listadoCategorias}
          fechaGoogle={fechaGoogle}/>}
        />
        <Route
          path="/controlclubs"
          element={<ControlClubs
            usuario={usuario} 
            idTorneoParticipando={idTorneoParticipando} 
            torneoEspecifico={torneoEspecifico}
            setListPruebas={setListPruebas}
            listPruebas={listPruebas}
            pruebasCompletas={pruebasCompletas}
            listadoCategorias={listadoCategorias}/>}
        />
        <Route
          path="/inscripciones"
          element={<InscripcionNadadores 
            usuario={usuario} 
            idTorneoParticipando={idTorneoParticipando} 
            torneoEspecifico={torneoEspecifico}
            setListPruebas={setListPruebas}
            listPruebas={listPruebas}
            pruebasCompletas={pruebasCompletas}
            listadoCategorias={listadoCategorias}/>}
        />
        <Route
          path="/"
          element={<Torneos
            setParticipando={setParticipando}
            setIdTorneoParticipando={setIdTorneoParticipando}
            torneos={torneos}
            setTorneoEspecifico={setTorneoEspecifico}
            usuario={usuario}
            setVisible={setVisible}
            fechaGoogle={fechaGoogle}/>}
        />
      </Routes>
      <Dialog visible={dialogQrCode} style={{ width: '600px', height: '600px' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={`QR Code`} modal className="p-fluid" onHide={() => setDialogQrCode(false)}>
        <div className="alert alert-success alert-dismissible fade show text-start" role="alert">
          <h4>¡IMPORTANTE!</h4>
          <p className="mb-0">PROWEBSPORTS te invita cordialmente a compartir este código QR con los familiares de los deportistas. Así podrán seguir de cerca los resultados durante el evento. ¡No te pierdas ni un momento de la 
            acción! <span onClick={() => generarQrCodeMayorCalidad().download({ name: "qr resultados", extension: "png" })} className="text-primary" style={{cursor: 'pointer'}}>Descargar QR</span></p>
        </div>
        <hr />
        <div id="qr-code" style={{ textAlign: 'center'}} />
        <hr />
      </Dialog>
      <Dialog visible={dialogCrearCuenta} style={{ width: '600px', height: '600px' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={`Crear Otra Cuenta`} modal className="p-fluid" onHide={() => setDialogCrearCuenta(false)}>
        <div className="alert alert-success alert-dismissible fade show text-start" role="alert">
          <h4>¡IMPORTANTE!</h4>
          <p className="mb-0">Puede crear múltiples cuentas con correos y contraseñas diferentes. 
            El nombre del club {usuario.nombreclub}, la abreviatura {usuario.abreviatura} y los privilegios se mantendrán. 
            Por otro lado, las cuentas creadas conservarán su privacidad, es decir, los deportistas no se compartirán entre ellas.</p>
        </div>
        <form className="column g-3" onSubmit={onSubmitCrearCuenta}>
          <div className="p-inputgroup text-center mb-1">
            <h2 className="w-100">{usuario.nombreclub}</h2>
          </div>
          <div className="p-inputgroup flex-1 mb-1">
            <span className="p-inputgroup-addon">
                <i className="pi pi-at"></i>
            </span>
            <InputText type="email" name="email" placeholder="E-mail@example.com" className="p-2" required/>
          </div>
          <div className="p-inputgroup flex-1 mb-1">
            <span className="p-inputgroup-addon">
                <i className={contrasena !== '' && contrasena.length >= 6 ? 'pi pi-lock' : 'pi pi-unlock'}></i>
            </span>
            <Password value={contrasena} onChange={(e) => setContrasena(e.target.value)} placeholder="Contraseña" toggleMask minLength={6} required
            className={`${contrasena !== '' && contrasena.length >= 6 ? '' : 'p-invalid'}`}
            promptLabel="Min. 6 Caracteres"
            weakLabel="Débil"
            mediumLabel="Media"
            strongLabel="Fuerte"/>
          </div>
          <div className="p-inputgroup flex-1 mb-1">
            <span className="p-inputgroup-addon">
                <i className={contrasena === validarContrasena && validarContrasena !== '' && validarContrasena.length >= 6 ? 'pi pi-lock' : 'pi pi-unlock'}></i>
            </span>
            <Password value={validarContrasena} onChange={(e) => setValidarContrasena(e.target.value)} placeholder="Validar Contraseña" toggleMask minLength={6} required
            className={`${contrasena === validarContrasena && validarContrasena !== '' && validarContrasena.length >= 6 ? '' : 'p-invalid'}`}
            feedback={false}/>
          </div>
          <div className="d-flex justify-content-center">
            <Button type="submit" icon='pi pi-user' label="CREAR OTRA CUENTA" className="btn btn-success w-50"></Button>
          </div>
        </form>
      </Dialog>
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Cambiar Contraseña</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <img className="w-50 mb-2" src={require('../img/img-logo-swimmingt.webp')} alt="logo prowebsports" />
            <form className="column g-3" onSubmit={onSubmitCambioContrasena}>
              <div className="position-relative d-flex align-items-center w-100 mb-2">
                <input
                  type="hidden"
                  name="nombre"
                  className="form-control"
                  disabled
                  value={usuario.nombreclub}
                  placeholder="nombre"
                />
                <input
                  type="hidden"
                  name="email"
                  className="form-control"
                  disabled
                  value={usuario.email}
                  placeholder="email"
                />
                <input
                  type={visibilityActual ? "text" : "password"}
                  className="form-control"
                  name="passwordActual"
                  placeholder={visibilityActual ? "Contraseña actual" : "********"}
                  minLength={6}
                  required
                />
                {visibilityActual ? (
                  <img
                    src={require("../img/vista.gif")}
                    className="vista-gif"
                    style={{ right: "10px", width: "2em"}}
                    alt="icono gif ojo password"
                    onClick={clickVisibilityActual}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className="vista-password"
                    style={{ right: "10px" }}
                    onClick={clickVisibilityActual}
                  />
                )}
              </div>
              <div className="position-relative d-flex align-items-center w-100 mb-2">
                <input
                  type={visibilityNuevo ? "text" : "password"}
                  className="form-control"
                  name="passwordNuevo"
                  placeholder={visibilityNuevo ? "Nueva contraseña" : "********"}
                  minLength={6}
                  required
                />
                {visibilityNuevo ? (
                  <img
                    src={require("../img/vista.gif")}
                    className="vista-gif"
                    style={{ right: "10px", width: "2em" }}
                    alt="icono gif ojo password"
                    onClick={clickVisibilityNuevo}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className="vista-password"
                    style={{ right: "10px" }}
                    onClick={clickVisibilityNuevo}
                  />
                )}
              </div>
              <input
                type="submit"
                className="btn submit"
                value="Guardar cambios"
              />
            </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Panel;
