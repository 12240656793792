import React, { useState, useEffect, useRef } from 'react';
import { toast } from "react-hot-toast";
import { motion } from "framer-motion";
import { abrirBaseDeDatosLocal, deportistaEliminadoPWS, deportistaRegistradoPWS, eliminarNadador, organizarPorTiempos, registrarClubAlTorneo, registrarDeportistaGeneral, registrarDirectivosClub, registrarNadador, registrarRelevo, sacarEdadDeLaCategoria, sacarNadadoresDeLosClubs, traerDeportistaGeneral, traerDirectivos, traerNadadores, traerPruebasParaRegistroJPC, verificarLimpiar}  from "../controllers/configuracionFirebase";
import { Tooltip as Mensaje } from "react-tooltip";
import { acomodarTextoCategoria } from '../controllers/estilos';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import Swal from 'sweetalert2';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { reporteInscritos } from '../controllers/pdfs';
import { saveAs } from 'file-saver';
import { datosAdmin } from '../controllers/administrador';
import Conctatenos from './Conctatenos';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { OverlayPanel } from 'primereact/overlaypanel';
import { extraerDatosPdf } from '../controllers/ai';
import { getAccuWeatherLocationKey, getClima } from '../controllers/apiGoogleMaps';
import { Tag } from 'primereact/tag';

const InscripcionNadadores = ({ 
  usuario,
  idTorneoParticipando, 
  torneoEspecifico,
  setListPruebas,
  listPruebas,
  pruebasCompletas, 
  listadoCategorias}) => {

  const [ nadadores, setNadadores ] = useState([]);
  const [ nadadoresRelevo, setNadadoresRelevo ] = useState([]);
  const [ globalFilter, setGlobalFilter ] = useState(null);
  const [ expandedRows, setExpandedRows ] = useState(null);
  const [ dialogInscripcion, setDialogInscripcion ] = useState(false);
  const [ dialogDirectivos, setDialogDirectivos ] = useState(false);
  const [ dialogRelevos, setDialogRelevos ] = useState(false);
  const [ mostrar, setMostrar ] = useState(false);
  const [ pruebasTiempos, setPruebasTiempos ] = useState([]);
  const [ pruebasId, setPruebasId ] = useState([]);
  const [ mostrarCategorias, setMostrarCategorias ] = useState([]);
  const [ sexo, setSexo ] = useState('');
  const [ edadRegistrada, setEdadRegistrada ] = useState();
  const [ mostrarJornadas, setMostrarJornadas ] = useState([]);
  const [ deportistas, setDeportistas ] = useState([]);
  const [ deportistaGeneral, setDeportistaGeneral ] = useState();
  const [ modificar, setModificar ] = useState(false);
  const [ pdfUrl, setPdfUrl ] = useState();
  const [ directivos, setDirectivos ] = useState([{nombre: '', cel: '', rol: ''}]);
  const [ relevos, setRelevos ] = useState([]);
  const [ elegirRelevos, setElegirRelevos ] = useState(-1);
  const [ relevoElegido, setRelevoElegido ] = useState('');
  const [ esRelevoMaster, setEsRelevoMaster ] = useState(false);
  const [ listaDeportistasRelevo, setListaDeportistasRelevo ] = useState([]);
  const [ relevoEdadInicioFin, setRelevoEdadInicioFin ] = useState({
    edadI: 0,
    edadF: 0
  });
  const [ inputsDeportistasRelevos, setInputsDeportistasRelevos ] = useState([{
    name: '',
    code: '',
    edad: 0
  }]);
  const [ nadadoresSinPruebas, setNadadoresSinPruebas ] = useState([]);
  const [ climaHoy, setClimaHoy ] = useState();
  const [ climaEvento, setClimaEvento ] = useState();
  const pdfEjemplo = useRef(null);

  function descargar(){
    saveAs(pdfUrl, `Reporte Inscripción ${usuario.nombreclub}.pdf`);
  }

  function calcularEdad(fecha) {
    if(torneoEspecifico.edCumplida === 'no'){
      let hoy = new Date();
      let fechaNacimiento = fecha;
      let cumple = fechaNacimiento.split('-');
      let cumpleanosDia = parseInt(cumple[2]);
      let cumpleanosMes = parseInt(cumple[1])
      let cumpleanosAno = parseInt(cumple[0]);
      let edad = hoy.getFullYear() - cumpleanosAno
      let m = (hoy.getMonth() + 1) - cumpleanosMes;
  
      if((cumpleanosMes) <= 12){
        edad--;
      }
  
      if(cumpleanosMes > 12){
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanosDia)) {
          edad--;
        }
      }
  
      return edad;
    }
    if(torneoEspecifico.edCumplida === 'si'){
      let hoy = new Date();
      let fechaNacimiento = fecha;
      let cumple = fechaNacimiento.split('-');
      let cumpleanosAno = parseInt(cumple[0]);
      let edad = hoy.getFullYear() - cumpleanosAno;

      return edad;
    }
  }
  
  function sacarEdadyGenero(){
    const fecha = document.getElementById('fechaNacimiento').value;
    const auxSexo = document.getElementById('genero').value;
    setSexo(auxSexo);
    if(fecha !== ""){
      if(parseInt(edadRegistrada) !== calcularEdad(fecha)){
        setMostrar(false);
      }
      setEdadRegistrada(calcularEdad(fecha));
    }
  }

  function sacarCategoriaInscita(){
    let auxCategoria = new Set();
    listPruebas.forEach((lisPrue)=>{
      const relevo = lisPrue.prueba.match(/Relevo/g);
      if(!relevo){
        lisPrue.requisitos.forEach((req)=>{
          if(req.genero === 'Mixto'){
            if(parseInt(req.edadInicio) <= edadRegistrada && parseInt(req.edadFin) >= edadRegistrada){
              auxCategoria.add(lisPrue.categoria);
            }
          }else{
            if(parseInt(req.edadInicio) <= edadRegistrada && parseInt(req.edadFin) >= edadRegistrada && req.genero === sexo){
              auxCategoria.add(lisPrue.categoria);
            }
          }
        })
      }
    })
    return Array.from(auxCategoria);
  }

  function elegirPruebas(){
    setMostrar(true);
    if(!edadRegistrada || sexo === ''){
      toast.error('Por favor, llena primero los campos.');
    }else{
      const mostrarJornadas = [];
      const categorias = new Set();
      torneoEspecifico.jornadas.forEach((jor)=>{
        const auxPruebas = [];
        const auxJornada = {}
        jor.categoria.forEach((cate)=>{
          const sacarCategoria = sacarCategoriaInscita();
          if(sacarCategoria.includes(cate)){
            categorias.add(cate);
            jor.pruebas.forEach((prue)=>{
              const auxPrueba = listPruebas.filter((listPrue)=>{return listPrue.id === prue.idPrueba});
              if(auxPrueba.length !== 0){
                const auxP = new Set();
                const relevo = auxPrueba[0].prueba.match(/Relevo/g);
                if(!relevo){
                  auxPrueba[0].requisitos.forEach((req)=>{
                    if(req.genero === 'Mixto'){
                      if(parseInt(req.edadInicio) <= edadRegistrada && parseInt(req.edadFin) >= edadRegistrada){
                        auxP.add(auxPrueba[0]);
                      }
                    }else{
                      if(parseInt(req.edadInicio) <= edadRegistrada && parseInt(req.edadFin) >= edadRegistrada && req.genero === sexo){
                        auxP.add(auxPrueba[0]);
                      }
                    }
                  })
                }
                auxPruebas.push(...Array.from(auxP));
              }
            })
          }
        })

        const sacarPruebasRepetidas = new Set();
        const auxIdsPruebas = new Set();

        auxPruebas.forEach((prue)=>{
          if(!auxIdsPruebas.has(prue.id)){
            auxIdsPruebas.add(prue.id);
            sacarPruebasRepetidas.add(prue);
          }
        })

        if(Array.from(sacarPruebasRepetidas).length !== 0){
          auxJornada.jornada = jor.jornada;
          auxJornada.pruebas = Array.from(sacarPruebasRepetidas);
          mostrarJornadas.push(auxJornada);
        }
      })

      if(mostrarJornadas.length === 0){
        toast.error('La categoria que intenta registrar, no compite en este torneo.');
      }
      
      setMostrarJornadas(mostrarJornadas);
      setMostrarCategorias(Array.from(categorias));
    }
  }

  function registrarFirebase(id, deportistaARegistrar){
    let auxArr = [];
    const auxNadadores = [];
    nadadores.forEach((nad)=>{
      if(nad.identificacion !== deportistaARegistrar.identificacion){
        auxNadadores.push(nad);
      }
    })

    if(auxNadadores.length !== 0){
      if(auxNadadores.length < 1){
        auxArr = [deportistaARegistrar, auxNadadores];
        registrarNadador(deportistaARegistrar.nombre, id, auxArr, usuario.id);
      }else{
        auxArr = [deportistaARegistrar, ...auxNadadores];
        registrarNadador(deportistaARegistrar.nombre, id, auxArr, usuario.id);
      }
    }else{
      auxArr = [deportistaARegistrar];
      registrarNadador(deportistaARegistrar.nombre, id, auxArr, usuario.id);
    }
  }

  function nombreNadadorMayuscula(nombre){
    const nombreMinuscula = nombre.toLowerCase();
    const nombrecompleto = nombreMinuscula.split(' ');
    let nombreMayuscula = '';
    for (let i = 0; i < nombrecompleto.length; i++) {
      nombreMayuscula += nombrecompleto[i].charAt(0).toUpperCase() + nombrecompleto[i].slice(1) + " ";
    }
    return nombreMayuscula.trim();
  }

  function sacarPruebas(){
    const auxPruebas = [];
    const auxPruebasTiempos = [];
    const pruebasTimes = [];
    const pruebas = [];
    for (let i = 0; i < torneoEspecifico.jornadas.length; i++) {
      for (let j = 0; j < parseInt(torneoEspecifico.jornadas[i].cantPruebas); j++) {
        const prueba = document.getElementById(`jornada${i}prueba${j}`) !== null ? document.getElementById(`jornada${i}prueba${j}`).value : '';
        const pruebaTiempoMin = document.getElementById(`jornada${i}prueba${j}TiempoMinutos`) !== null ? document.getElementById(`jornada${i}prueba${j}TiempoMinutos`).value !== '' ? document.getElementById(`jornada${i}prueba${j}TiempoMinutos`).value : '0' : '';
        const pruebaTiempoSeg = document.getElementById(`jornada${i}prueba${j}TiempoSegundos`) !== null ? document.getElementById(`jornada${i}prueba${j}TiempoSegundos`).value !== '' ? document.getElementById(`jornada${i}prueba${j}TiempoSegundos`).value : '0' : '';
        const pruebaTiempoMili = document.getElementById(`jornada${i}prueba${j}TiempoMilisegundos`) !== null ? document.getElementById(`jornada${i}prueba${j}TiempoMilisegundos`).value !== '' ? document.getElementById(`jornada${i}prueba${j}TiempoMilisegundos`).value : '0' : '';
        
        const auxTiempos = { 
          prueba: prueba,
          record: pruebaTiempoMin + ':' + pruebaTiempoSeg + ':' + pruebaTiempoMili
        }
        
        auxPruebas.push(prueba);
        auxPruebasTiempos.push(auxTiempos);
      }
    }

    auxPruebas.forEach((auxPrue)=>{
      if(auxPrue !== ''){
        pruebas.push(auxPrue);
      }
    })
    
    auxPruebasTiempos.forEach((auxPrue)=>{
      if(auxPrue.prueba !== ''){
        pruebasTimes.push(auxPrue);
      }
    })
    
    setPruebasTiempos(pruebasTimes);
    setPruebasId(pruebas);
  }

  function validarPruebas(arr) {
    const conjunto = new Set();
    for (const elemento of arr) {
      // Verificar si el elemento no es vacío
      if (elemento !== "") {
        if (conjunto.has(elemento)) {
          // Elemento duplicado encontrado
          return true;
        }
        conjunto.add(elemento);
      }
    }
    // No se encontraron elementos duplicados
    return false;
  }

  function sacarIdentificaciones(ti){
    let identificacion = false;
    if(!modificar){
      deportistas.forEach((deport)=>{
        if(deport.identificacion === ti){
          identificacion = true;
        }
      })
    }

    return identificacion;
  }

  function validarIdentificacion(ti){
    let valido = true;
    if(ti.length < 6 || ti.length > 10 || /^(\d)\1+$/.test(ti)){
      valido = false;
    }
    return valido;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const deportistaARegistrar = {
      club: usuario.abreviatura,
      nombre: nombreNadadorMayuscula(e.target.nombre.value),
      identificacion: e.target.identificacion.value,
      fechaNacimiento: e.target.fechaNacimiento.value,
      edad: parseInt(e.target.edad.value),
      genero: e.target.genero.value,
      nombreclub: usuario.nombreclub,
      pruebas: pruebasId,
      categoria: sacarEdadDeLaCategoria(sacarCategoriaInscita(), edadRegistrada, listadoCategorias, listPruebas, pruebasId),
      verificado: e.target.verificacion.value === '' ? 'No Verificado' : e.target.verificacion.value,
      codePais: usuario.codePais
    }
    if(validarPruebas(deportistaARegistrar.pruebas)){
      toast.error('Al parecer intentas registrar al deportista en 2 o mas pruebas iguales, eso no es posible, por favor verifica para poder continuar.');
    }else if(deportistaARegistrar.pruebas.length === 1 && deportistaARegistrar.pruebas[0] === ''){
      toast.error('No has seleccionado las pruebas todavía. En caso de que las hayas elegido y el sistema no te permita avanzar. Por favor, recarga la plataforma.');
    }else if(!validarPruebas(deportistaARegistrar.pruebas) && deportistaARegistrar.pruebas.length <= parseInt(torneoEspecifico.jornadas[0].cantPruebas)){
      if(sacarIdentificaciones(deportistaARegistrar.identificacion) || !validarIdentificacion(deportistaARegistrar.identificacion)){
        if(sacarIdentificaciones(deportistaARegistrar.identificacion)){
          toast('Ya existe un/una deportista con esta identificación ' + deportistaARegistrar.identificacion,{
            icon: '🤨'
          })
        }
        if(!validarIdentificacion(deportistaARegistrar.identificacion)){
          toast('Esta identificación ' + deportistaARegistrar.identificacion + ' no es valida',{
            icon: '🤨'
          })
        }
      }else{
        if(modificar){
          if(deportistaARegistrar.pruebas.length === 0){
            toast('Debes hacer una modificación en las pruebas o darle clic en una de las pruebas.',{
              icon: '🤨'
            })
          }else{
            organizarPorTiempos(deportistaARegistrar.identificacion, pruebasTiempos, mostrarJornadas, torneoEspecifico?.piscina).then((org)=>{
              deportistaARegistrar.records = org;
              registrarFirebase(idTorneoParticipando, deportistaARegistrar);
            });
            if(!modificar){
              deportistaRegistradoPWS(datosAdmin.conexion, torneoEspecifico.id);
            }
            setEdadRegistrada();
            setDeportistaGeneral();
            setMostrarJornadas([]);
            setPruebasId([]);
            setModificar(false);
            e.target.reset();
          }
        }else{
          organizarPorTiempos(deportistaARegistrar.identificacion, pruebasTiempos, mostrarJornadas, torneoEspecifico?.piscina).then((org)=>{
            deportistaARegistrar.records = org;
            registrarFirebase(idTorneoParticipando, deportistaARegistrar);
          });
          if(deportistaARegistrar.edad >= 5){
            registrarDeportistaGeneral(deportistaARegistrar.identificacion, deportistaARegistrar, usuario.departamento);
          }
          if(!modificar){
            deportistaRegistradoPWS(datosAdmin.conexion, torneoEspecifico.id);
          }
          setEdadRegistrada();
          setDeportistaGeneral();
          setMostrarJornadas([]);
          setPruebasId([]);
          setModificar(false);
          e.target.reset();
        }
      }
    }else{
      toast(`Has excedido el límite máximo de pruebas permitidas, máximo (${torneoEspecifico.jornadas[0].cantPruebas}).`,{
        icon: '🤨'
      })
    }
  };

  const onSubmitRelevo = (e) => {
    e.preventDefault();
    let relevosVacios = false;

    inputsDeportistasRelevos.forEach((inp) =>{
      if(inp.name === ''){
        relevosVacios = true;
      }
    })
    
    if(inputsDeportistasRelevos.length !== 0 && !relevosVacios){
      let deportista1 = {};
      const identificacionEquipo = [];
      const nombresEquipo = [];
      const edades = [];
      inputsDeportistasRelevos.forEach((deport)=>{
        const deportista = deportistas.filter((depor) => depor.nombreclub === usuario.nombreclub)[deport.code]
        deportista1 = deportista;
        identificacionEquipo.push(deportista.identificacion);
        nombresEquipo.push(deportista.nombre);
        edades.push(deportista.edad);
      })

      const deportistaARegistrar = {
        club: usuario.abreviatura,
        nombre: usuario.nombreclub,
        identificacion: `${identificacionEquipo}`,
        fechaNacimiento: '',
        edad: deportista1.edad, 
        edades: edades,
        genero: relevos[elegirRelevos].filter((rel) => rel.id === parseInt(relevoElegido))[0]?.genero,
        nombreclub: usuario.nombreclub,
        pruebas: [relevos[elegirRelevos].filter((rel) => rel.id === parseInt(relevoElegido))[0]?.prueba.id],
        categoria: relevos[elegirRelevos].filter((rel) => rel.id === parseInt(relevoElegido))[0]?.prueba.prueba,
        equipo: nombresEquipo,
        records: [
          {prueba: relevos[elegirRelevos].filter((rel) => rel.id === parseInt(relevoElegido))[0]?.prueba.id,
           record: '0:0:0'}
        ],
        codePais: usuario.codePais
      }

      if(!validarPruebas(deportistaARegistrar.equipo)){
        const auxNadadoresRelevos = [...nadadoresRelevo]; 
        auxNadadoresRelevos.push(deportistaARegistrar);
        registrarRelevo(idTorneoParticipando, auxNadadoresRelevos, usuario.id, 'registrar');
      }else{
        toast.error('Se encontro un deportista repetido. Por favor verifica.');
      }
    }else{
      toast.error('Llena todos los campos.');
    }
  }
  
  function buscarDeportista(){
    traerDeportistaGeneral(document.getElementById('deportCC').value, usuario.nombreclub, calcularEdad).then((dep) =>{
      if(dep){
        setDeportistaGeneral(dep);
        setEdadRegistrada(dep.edad);
      }else{
        setDeportistaGeneral();
        setEdadRegistrada();
      }
    });
  }

  function anadirCamposDirectivo(index){
    const auxDirectivos = [...directivos];
    if(auxDirectivos.length < parseInt(torneoEspecifico.jornadas[0].cantDirectivos)){
      const auxObjDirectivo = {nombre: '', cel: '', rol: ''};
      auxDirectivos.splice(index + 1, 0, auxObjDirectivo);
      setDirectivos(auxDirectivos);
    }
  }

  function quitarCamposDirectivo(index){
    const auxDirectivos = [...directivos];
    if(auxDirectivos.length > 1){
      auxDirectivos.splice(index, 1);
      setDirectivos(auxDirectivos);
    }
  }

  function registrarDirectivos(){
    const auxDirectivos = [...directivos];
    for (let i = 0; i < directivos.length; i++) {
      const nombreDire = document.getElementById(`nombreDirectivo${i}`) !== null ? document.getElementById(`nombreDirectivo${i}`).value : '';
      const celDire = document.getElementById(`cel${i}`) !== null ? document.getElementById(`cel${i}`).value : '';
      const rolDire = document.getElementById(`rol${i}`) !== null ? document.getElementById(`rol${i}`).value : '';

      auxDirectivos[i].nombre = nombreNadadorMayuscula(nombreDire);
      auxDirectivos[i].cel = celDire;
      auxDirectivos[i].rol = rolDire;
    }

    setDirectivos(auxDirectivos);
    registrarDirectivosClub(usuario.id, auxDirectivos, torneoEspecifico.id);
  }

  function pruebasExtras(cant, prueba, indexPrueba){
    const auxCantPruebas = Array.from({ length: cant}, (_, index) => index);
     return auxCantPruebas.map((items, indexPrueExtra)=>(
      <tr key={indexPrueExtra}>
        <td>
          <div className="form-floating mb-2">
            <select style={{minWidth: '100px'}} className='form-select' id={`jornada${indexPrueba}prueba${indexPrueExtra}`} defaultValue={modificar ? deportistaGeneral['records' + indexPrueba][indexPrueExtra] ? deportistaGeneral['records' + indexPrueba][indexPrueExtra].prueba : '' : '0'} onChange={() => sacarPruebas()}>
              <option value=''>{`Ninguna`}</option>
              {
                prueba.pruebas.map((prue, indexPrue)=>{
                  return <option disabled={pruebasId.includes(prue.id)} className={`${pruebasId.includes(prue.id) ? 'bg-danger text-light' : ''}`} key={indexPrue} value={prue.id}>{prue.prueba + ' - ' + prue.categoria}</option>
                })
              }
            </select>
            <label htmlFor="genero">{prueba.pruebas.length} Pruebas Disponibles</label>
          </div>
        </td>
        <td className='d-flex justify-content-around align-items-center'>
          <div className="form-floating pb-2">
            <input style={{width: '68px'}} disabled={torneoEspecifico.regTiempo === 'no' ? true : false} type="number" id={`jornada${indexPrueba}prueba${indexPrueExtra}TiempoMinutos`} className='form-control' placeholder='0' defaultValue={modificar ? parseInt(deportistaGeneral['records' + indexPrueba][indexPrueExtra] ? deportistaGeneral['records' + indexPrueba][indexPrueExtra].record[0] : 0) : '0'} onChange={() => sacarPruebas()}/>
            <label htmlFor="deportCC">Min.</label>
          </div>
          <div className="form-floating pb-2">
            <input style={{width: '68px'}} disabled={torneoEspecifico.regTiempo === 'no' ? true : false} type="number" id={`jornada${indexPrueba}prueba${indexPrueExtra}TiempoSegundos`} className='form-control' placeholder='0' defaultValue={modificar ? parseInt(deportistaGeneral['records' + indexPrueba][indexPrueExtra] ? deportistaGeneral['records' + indexPrueba][indexPrueExtra].record[1] : 0) : '0'} onChange={() => sacarPruebas()}/>
            <label htmlFor="deportCC">Seg.</label>
          </div>
          <div className="form-floating pb-2">
            <input style={{width: '68px'}} disabled={torneoEspecifico.regTiempo === 'no' ? true : false} type="number" id={`jornada${indexPrueba}prueba${indexPrueExtra}TiempoMilisegundos`} className='form-control' placeholder='0' defaultValue={modificar ? parseInt(deportistaGeneral['records' + indexPrueba][indexPrueExtra] ? deportistaGeneral['records' + indexPrueba][indexPrueExtra].record[2] : 0) : '0'} onChange={() => sacarPruebas()}/>
            <label htmlFor="deportCC">Mili.</label>
          </div>
        </td>
      </tr>
     ))
  }

  const header = (
    <div className="d-flex justify-content-start align-items-center">
      <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
      </span>
    </div>
  );

  const agregarInscrito = () => {
    return (
      <div className="flex flex-wrap gap-2">
        {torneoEspecifico?.evento !== 'finales' && !torneoEspecifico?.inscripcionesCerradas ?(
          <Button icon="pi pi-plus" severity="success" raised tooltip='Inscribir' tooltipOptions={{position: 'right', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => setDialogInscripcion(true)}/>
        ):(
          <></>
        )}
        <Button className='ms-1 me-1' icon="pi pi-flag" severity="warning" raised tooltip='Relevos' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => setDialogRelevos(true)}/>
        <Button className='me-1' icon="pi pi-file-pdf" severity="danger" raised tooltip='Reporte .pdf' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => reporteInscritos(pruebasCompletas, usuario, nadadores, setPdfUrl, torneoEspecifico)}/>
        <Button className='me-1' icon="pi pi-users" severity="primary" raised tooltip='Directivos' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => setDialogDirectivos(true)}/>
      </div>
    );
  };

  function sacarPruebasTiempos(nadador){
    const auxPrueba = [];
    nadador.records.forEach((prue)=>{
      const prueba = listPruebas.filter((listPrue)=>{return listPrue.id === prue.prueba});
      prue.nombre = prueba[0].prueba;
      auxPrueba.push(prue);
    })
    return auxPrueba;
  }

  const rowExpansionTemplate = (data) => {
    return (
      <DataTable value={sacarPruebasTiempos(data)} emptyMessage="No hay pruebas registradas.">
        <Column header="#" body={(_, index)=>{return index.rowIndex + 1}} style={{ width: '30px'}}></Column>
        <Column field="nombre" header="Prueba" style={{ width: '110px'}}></Column>
        <Column field="record" header="Tiempo" style={{ width: '80px'}}></Column>
      </DataTable>
    );
  };

  const acciones = (insc) => {
    return (
      <div>
        {torneoEspecifico?.evento !== 'finales' ?(
          <Button icon="pi pi-pencil" size='small' text severity="secondary" tooltip='Editar' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => modificarInscrito(insc)}/>
        ):(
          <></>
        )}
        <Button text icon="pi pi-times" size='small' severity="danger" tooltip='Eliminar' tooltipOptions={{position: 'left', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => clickNadador(insc)}/>
      </div>
    );
  };

  const subirPdf = (event, insc) =>{
    const selectedFile = event.target.files[0];
    extraerDatosPdf(selectedFile, usuario, insc, idTorneoParticipando, registrarFirebase);
    const fileInput = document.getElementById(`subirPdf${insc.identificacion}`);
    fileInput.value = '';
  }

  const accionesVerificacion = (insc) => {
    if(!insc.verificado){
      return (
        <div>
          <input type="file" id={`subirPdf${insc.identificacion}`} hidden onChange={(ev) => subirPdf(ev, insc)}/>
          <label htmlFor={`subirPdf${insc.identificacion}`}><i title='Verificar' className="pi pi-id-card text-danger" style={{cursor: 'pointer'}}></i></label>
        </div>
      );
    }else{
      if(insc.verificado === 'No Verificado'){
        return (
          <div>
            <input type="file" id={`subirPdf${insc.identificacion}`} hidden onChange={(ev) => subirPdf(ev, insc)}/>
            <label htmlFor={`subirPdf${insc.identificacion}`}><i title='Verificar' className="pi pi-id-card text-danger" style={{cursor: 'pointer'}}></i></label>
          </div>
        );
      }else{
        return (
          <div>
            <label><i title='Verificado/a' className="pi pi-id-card text-success"></i></label>
          </div>
        );
      }
    }
  };

  function clickNadador(insc){
    const alerta = Swal.mixin({
      toast: true,
      showCancelButton: true
    })
    alerta.fire({
      html: `<div class="w-100 position-relative">
      <img style="width: 100px; position: absolute; top: 10px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
      <p style="z-index: 10; font-family: Poppins" class="mb-0">Esta seguro de eliminar a el/la deportista ${insc.nombre}
      </div>`,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: 'rgb(18, 53, 248)',
      padding: 4,
      cancelButtonText: 'Cancelar',
    }).then((respuesta) =>{ 
      if(respuesta.isConfirmed){
        const auxNadadores = [...nadadores]
        const index = auxNadadores.findIndex((nad)=> nad.identificacion === insc.identificacion);
        if(index !== -1){
          auxNadadores.splice(index, 1);
          eliminarNadador(insc.nombre, idTorneoParticipando, usuario.id, auxNadadores);
          sacarNadadoresDeLosClubs(idTorneoParticipando, setDeportistas);
          deportistaEliminadoPWS(datosAdmin.conexion, torneoEspecifico.id);
        }
      }
    })
  }

  function eliminarNadadorRelevo(index){
    const alerta = Swal.mixin({
      toast: true,
      showCancelButton: true
    })
    alerta.fire({
      html: `<div class="w-100 position-relative">
      <img style="width: 100px; position: absolute; top: 10px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
      <p style="z-index: 10; font-family: Poppins" class="mb-0">Esta seguro de eliminar el relevo
      </div>`,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: 'rgb(18, 53, 248)',
      padding: 4,
      cancelButtonText: 'Cancelar',
    }).then((respuesta) =>{
      if(respuesta.isConfirmed){
        const auxNadadoresRelevo = [...nadadoresRelevo]
        auxNadadoresRelevo.splice(index, 1);
        registrarRelevo(idTorneoParticipando, auxNadadoresRelevo, usuario.id, 'eliminar');
      }
    })
  }

  function modificarInscrito(insc){
    setDialogInscripcion(true);
    torneoEspecifico.jornadas.forEach((jor, indexJor)=>{
      const auxRecords = []; 
      jor.pruebas.forEach((prueJor)=>{
        insc.records.forEach((inscPrue)=>{
          if(prueJor.idPrueba === inscPrue.prueba){
            auxRecords.push(inscPrue);
          }
        })
      })

      insc['records' + indexJor] = auxRecords;
      insc['records' + indexJor].forEach((rec)=>{
        rec.record = rec.record.length !==  3 ? rec.record.split(':') : rec.record;
      })
    })
    setEdadRegistrada(calcularEdad(insc.fechaNacimiento));
    setDeportistaGeneral(insc);
    setModificar(true);
  }

  const footerGroupDeportista = (
    <ColumnGroup>
      <Row>
        <Column footer="Total:" colSpan={8} footerStyle={{ textAlign: 'right' }} />
        <Column footer={nadadores.length !== 0 ? ((nadadores.length * (torneoEspecifico.length !== 0 ? parseInt(torneoEspecifico.valorDeportista) : 0)) + (torneoEspecifico.length !== 0 ? parseInt(torneoEspecifico.valorClub) : 0)).toLocaleString() :(0)} />
      </Row>
    </ColumnGroup>
  );

  function sacarCantidadPruebas(jor){
    let auxPruebas = 0;
    jor.requisitos.forEach((req)=>{
      if(parseInt(req.edadStart) <= edadRegistrada && parseInt(req.edadEnd) >= edadRegistrada){
        auxPruebas = parseInt(req.cantPruebas);
      }
    })
    return auxPruebas;
  }

  function agregarDatosAlInputs(valor, index){
    const auxDeportistaRelevo = [...inputsDeportistasRelevos];
    auxDeportistaRelevo[index] = valor;
    setInputsDeportistasRelevos(auxDeportistaRelevo);
  }

  const content = (
    <div className="d-flex align-items-center"> 
      <img alt="logo" src={require("../img/img-logo-swimmingt.webp")} width="50" />
      <div className="ms-2 text-danger">Sin Pruebas: <b>{nadadoresSinPruebas.toLocaleString()}</b></div>
    </div>
  );

  useEffect(() => {
    traerNadadores(idTorneoParticipando, usuario.id, setNadadores, setNadadoresRelevo);
  }, [usuario])

  useEffect(() => {
    if(!dialogInscripcion){
      setEdadRegistrada();
      setDeportistaGeneral();
      setMostrarJornadas([]);
      setModificar(false);
      setPruebasId([]);
    }
  }, [dialogInscripcion])

  useEffect(() => {
    if(!dialogRelevos || elegirRelevos){
      setListaDeportistasRelevo([]);
      setRelevoElegido('');

      if(!dialogRelevos){
        setElegirRelevos(-1);
      }
    }
  }, [dialogRelevos, elegirRelevos])

  useEffect(() => {
    if(usuario.nombreclub === datosAdmin.nombre || usuario.nombreclub === 'PROWEBSPORTS'){
      abrirBaseDeDatosLocal(idTorneoParticipando);
      verificarLimpiar(idTorneoParticipando);
    }

    function registrarClub(){
      const club = {
        idClub: usuario.id,
        abreviatura: '',
        email: usuario.email,
        nadadores: [],
        relevos: [],
        nombreclub: usuario.nombreclub,
        photo: usuario.photo
      }
      registrarClubAlTorneo(idTorneoParticipando, club , usuario.id, usuario.photo, torneoEspecifico);
    }

    registrarClub();
    traerPruebasParaRegistroJPC(setListPruebas);
    traerDirectivos(torneoEspecifico.id, usuario.id, setDirectivos);
  }, [usuario, idTorneoParticipando])

  useEffect(() => {
    sacarNadadoresDeLosClubs(idTorneoParticipando, setDeportistas);
  }, [idTorneoParticipando, nadadores])

  useEffect(() =>{
    if(modificar && !dialogInscripcion || !pdfUrl){
      const auxNadadores = [...nadadores];
      const nuevoNadadores = [];
      auxNadadores.forEach((auxNad)=>{
        const objNadador = {
          club: auxNad.club,
          edad: auxNad.edad,
          fechaNacimiento: auxNad.fechaNacimiento,
          genero: auxNad.genero,
          identificacion: auxNad.identificacion,
          nombre: auxNad.nombre,
          nombreclub: auxNad.nombreclub,
          pruebas: auxNad.pruebas,
          records: [],
          categoria: auxNad.categoria,
          verificado: auxNad.verificado,
          codePais: auxNad.codePais
        }
        auxNad.records.forEach((rec)=>{
          const objRecord = {
            prueba: rec.prueba
          }
          if(rec.record.length === 3){
            objRecord.record = `${rec.record[0]}:${rec.record[1]}:${rec.record[2]}`
          }else{
            objRecord.record = rec.record;
          }

          objNadador.records.push(objRecord);
        })

        nuevoNadadores.push(objNadador);
      })

      setNadadores(nuevoNadadores);
    }
  },[dialogInscripcion, modificar, pdfUrl])

  useEffect(() => {
    function sacarPruebasRelevos(){
      const relevosEncontrados = [];
      pruebasCompletas.forEach((pruebas)=>{
        const auxRelevos = [];
        pruebas.forEach((prue)=>{
          const relevo = prue.prueba.prueba.match(/Relevo/g);
          if(relevo){
            auxRelevos.push(prue);
          }
        })
        relevosEncontrados.push(auxRelevos);
      })
      setRelevos(relevosEncontrados);
    }

    sacarPruebasRelevos();
  }, [dialogRelevos])

  useEffect(() =>{
    function anadirInputDeportistaRelevo(){
      const auxDeportista = [...inputsDeportistasRelevos];
      const inputsNuevo = {
        name: '',
        code: '',
        edad: 0
      };
  
      Array.from({ length: 4 }, (_, index) => index).forEach((indexRel) =>{
        auxDeportista[indexRel] = inputsNuevo; 
      })
      setInputsDeportistasRelevos(auxDeportista);
    }

    function edadesPrueba(){
      const auxDeportistasNombres = [];
      if(relevoElegido !== ''){
        const aux = [];
        let relevoMaster = false;
        const auxRelevoEdadInicioFin = relevoEdadInicioFin;

        pruebasCompletas.forEach((prue)=>{
          const auxPrueba = prue.filter((auxPrue)=>{return auxPrue.id === parseInt(relevoElegido)});
          if(auxPrueba.length !== 0){
            aux.push(auxPrueba[0]);
            relevoMaster = auxPrueba[0].categoria.match(/40 a 79|80 a 119|120 a 159|160 a 199|200 a 239|240 a 279|280 a 319/g) ? true : false;
            if(relevoMaster){
              auxRelevoEdadInicioFin.edadI = parseInt(auxPrueba[0].edadInicio);
              auxRelevoEdadInicioFin.edadF = parseInt(auxPrueba[0].edadFin);
              setRelevoEdadInicioFin(auxRelevoEdadInicioFin);
            }
          }
        })
        if(aux.length !== 0){
          if(deportistas.filter((depor) => depor.nombreclub === usuario.nombreclub && (relevoMaster ? depor.edad >= 20 : (parseInt(aux[0].edadFin) >= depor.edad))).length !== 0){
            deportistas.filter((depor) => depor.nombreclub === usuario.nombreclub && (relevoMaster ? depor.edad >= 20 : (parseInt(aux[0].edadFin) >= depor.edad))).forEach((deport, indexDeport)=>{
              const objDeport = {
                name: `${deport.nombre}/${deport.edad} años/${deport.nombreclub}`,
                code: indexDeport,
                edad: deport.edad
              };
              auxDeportistasNombres.push(objDeport);
            })
          }
        }
        setListaDeportistasRelevo(auxDeportistasNombres);
        anadirInputDeportistaRelevo();
        setEsRelevoMaster(relevoMaster);
      }
    }

    edadesPrueba();
    anadirInputDeportistaRelevo();
  },[relevoElegido])

  useEffect(() =>{
    const auxNadadoresSinPruebas = [];
    nadadores.forEach((nad)=>{
      if(nad.pruebas.length === 0){
        auxNadadoresSinPruebas.push(nad.nombre)
      }
    })

    if(auxNadadoresSinPruebas.length !== 0){
      setNadadoresSinPruebas(auxNadadoresSinPruebas);
    }else{
      setNadadoresSinPruebas([]);
    }
  },[nadadores])

  useEffect(() =>{
    if(idTorneoParticipando){
      getAccuWeatherLocationKey(torneoEspecifico.ubicacionLatLng).then((lL)=>{
        getClima(lL, torneoEspecifico, setClimaHoy, setClimaEvento);
      });
    }
  },[])

  return (
    <>
      <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <div className='bg-light'>
          <div className="alert alert-success alert-dismissible fade show mb-0 pe-3 pb-0" role="alert">
            <h1>{torneoEspecifico.titulo ? torneoEspecifico.titulo.toUpperCase() : ''}</h1>
            <p style={{textAlign: 'justify'}}>
              <b>Asegura la confianza y transparencia en la participación de tus deportistas. </b>
              Verifica la edad de cada deportista sin necesidad de presentar tarjetas de identidad o cédulas durante el evento. 
              Simplemente haz clic en el ícono de la tarjeta junto al nombre de cada niñ@ no verificad@ y sube el archivo correspondiente en formato PDF o Imagen. 
              Nuestro sistema extraerá automáticamente la información necesaria del documento, garantizando un registro eficiente y una experiencia sin complicaciones. 
              <b> Cabe destacar que el documento no será almacenado en la plataforma. </b><button className='btn btn-info' onClick={(e) => pdfEjemplo.current.toggle(e)}>Ejemplo</button>
            </p> 
            <OverlayPanel ref={pdfEjemplo}>
              <div className='d-flex flex-column'>
                <img src={require('../img/img-cedula-ejemplo.png')} alt="img ejemplo" style={{width: '100px'}}></img>
              </div>
            </OverlayPanel>  
          </div>
          <motion.div className='d-flex flex-wrap justify-content-around align-items-center'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{duration: 1}}
          >
            {climaHoy ?(
              <div className='d-flex'>
                <div>
                  <p className={`m-0 ${climaHoy?.Temperature.Metric.Value >= 25 ? 'text-danger' : 'text-info'}`} style={{fontFamily: 'Poppins', textShadow: '0 1px 2px black'}}>{'HOY'}</p>
                  <hr className='m-0'/>
                  <p className='m-0' style={{fontFamily: 'Poppins', fontSize: '12px', lineHeight: 1}}>{climaHoy?.ciudad.toUpperCase()}</p>
                </div>
                <div className='position-relative' style={{width: '90px'}}>
                  <img className='w-100' src={climaHoy?.iconoUrl} alt="icono del clima" />
                </div>
                <div>
                  <p className={`mb-0 ${climaHoy?.Temperature.Metric.Value >= 25 ? 'text-danger' : 'text-info'}`} style={{fontFamily: 'Poppins', textShadow: '0 1px 2px black'}}>{climaHoy?.Temperature.Metric.Value + '°C'}</p>
                  <hr className='m-0'/>
                  <p className='m-0' style={{width: '70px', fontFamily: 'Poppins', fontSize: '12px', lineHeight: 1}}>{climaHoy?.WeatherText}</p>
                </div>
              </div>
            ):(
              <></>
            )}
            {climaEvento ?(
              <div className='d-flex'>
                <div>
                  <p className='m-0' style={{fontFamily: 'Poppins', textShadow: '0 1px 2px black'}}>{'EVENTO'}</p>
                  <hr className='m-0'/>
                  <p className='m-0' style={{fontFamily: 'Poppins', fontSize: '12px', lineHeight: 1}}>{climaEvento?.ciudad.toUpperCase()}</p>
                </div>
                <div className='position-relative' style={{width: '100px'}}>
                  <img className='w-100' src={climaEvento?.iconoUrl} alt="icono del clima" />
                  <p className='position-absolute m-0 bottom-0' style={{fontFamily: 'Poppins', fontSize: '12px', lineHeight: 1}}>{climaEvento?.WeatherText}</p>
                </div>
                <div>
                  <p className={`m-0 ${climaEvento?.tempmax >= 25 ? 'text-danger' : 'text-info'}`} style={{fontFamily: 'Poppins', textShadow: '0 1px 2px black'}}>{climaEvento?.tempmax + '°C'}</p>
                  <hr className='m-0'/>
                  <p className={`m-0 ${climaEvento?.tempmin >= 25 ? 'text-danger' : 'text-info'}`} style={{fontFamily: 'Poppins', textShadow: '0 1px 2px black'}}>{climaEvento?.tempmin + '°C'}</p>
                </div>
              </div>
            ):(
              <></>
            )}
          </motion.div>
          <Toolbar left={agregarInscrito}></Toolbar>
          {nadadoresSinPruebas.length !== 0 ? (
            <div className="card">
              <Message
                style={{
                    border: 'solid #696cff',
                    borderWidth: '0 0 0 6px',
                    color: '#696cff'
                }}
                className="border-danger justify-content-start"
                severity="error"
                content={content}
            />
            </div>
          ):(
            <></>
          )}
          <DataTable value={nadadores} selectionMode="single" dragSelection expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate} header={header} globalFilter={globalFilter} size='small' stripedRows paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} emptyMessage="No hay inscritos aún." 
          footerColumnGroup={footerGroupDeportista} tableStyle={{ minWidth: '61rem', margin: 'auto'}}>
            <Column expander style={{ width: '30px' }} />
            <Column header="#" body={(_, index)=>{return index.rowIndex + 1}} style={{ width: '30px'}}></Column>
            <Column body={(insc)=> accionesVerificacion(insc)} style={{ width: '10px'}}></Column>
            <Column field='nombre' sortable header="Nombre" body={(nadador) => nadador.pruebas.length !== 0 ? nadador.nombre : <>{nadador.nombre}<i className='ms-1 pi pi-times-circle text-danger'/></>} style={{ width: torneoEspecifico?.inscripcionesCerradas ? '220px' : '180px', fontWeight: 'bold' }}></Column>
            <Column field="identificacion" sortable header="Identificación" style={{ width: '70px'}}></Column>
            <Column field="fechaNacimiento" sortable header="Fec. Nto" style={{ width: torneoEspecifico?.inscripcionesCerradas ? '150px' : '110px'}}></Column>
            <Column field="edad" header="Edad" sortable style={{ width: '50px'}}></Column>
            <Column field="genero" header="Sexo" sortable style={{ width: '50px'}}></Column>
            <Column header="Aporte" body={() => {return `$${torneoEspecifico.length !== 0 ? parseInt(torneoEspecifico.valorDeportista).toLocaleString() : 0}`}} style={{ width: '80px'}}></Column>
            {!torneoEspecifico?.inscripcionesCerradas ?(
              <Column body={(insc)=> acciones(insc)} style={{ width: '105px'}}></Column>
            ):(
              <></>
            )}
          </DataTable>
        </div>
        <Dialog visible={dialogInscripcion} maximizable style={{ width: '46rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header='INSCRIPCIÓN' modal className="p-fluid" onHide={() => setDialogInscripcion(false)}>
          <div className="field">
          <form onSubmit={onSubmit} className='text-center'>
            <div className="form-floating">
              <input type="number" className='form-control mb-1' id='deportCC'/>
              <label htmlFor="deportCC">Buscar deportista con identificación</label>
            </div>
            <Button type='button' icon='pi pi-search' label='Buscar' className='btn btn-secondary' style={{borderRadius: '10px', width: '200px'}} onClick={() => buscarDeportista()}></Button>
            <hr className='m-1'/>
            <input className='form-control' type="hidden" name="edad" id='edad' value={edadRegistrada || ''}/>
            <div className="alert alert-warning alert-dismissible fade show mb-1 p-1" role="alert">
              <b>Edad Inscrito: </b>{deportistaGeneral?.verificado === 'Verificado' ? <Tag severity={'success'} icon={'pi pi-user'} value={(edadRegistrada || '0') + ' Años Verificado'}></Tag> : <Tag severity={'danger'} icon={'pi pi-user'} value={(edadRegistrada || '0') + ' Años No Verificado'}></Tag>}
            </div>
            <hr className='m-1'/>
            <div className='m-auto d-flex flex-wrap justify-content-between' style={{maxWidth: '800px'}}>
              <div className="m-1 form-floating" style={{width: window.innerWidth <= 500 ? '100%' : '270px'}}>
                <input className='form-control' type="text" maxLength={29} name="nombre" id='nombre' required defaultValue={deportistaGeneral ? deportistaGeneral.nombre : ''}/>
                <label htmlFor="nombre">Nombre Completo</label>
              </div>
              <div className="m-1 form-floating" style={{width: window.innerWidth <= 500 ? '100%' : '150px'}}>
                <input className='form-control' disabled={deportistaGeneral || modificar ? true : false} type="number" name="identificacion" id='identificacion' required defaultValue={deportistaGeneral ? deportistaGeneral.identificacion : ''}/>
                <label htmlFor="identificacion">Identificación</label>
              </div>
              <div className="m-1 form-floating" style={{width: window.innerWidth <= 500 ? '100%' : '190px'}}>
                <input className='form-control' disabled={deportistaGeneral ? deportistaGeneral.verificado === 'Verificado' ? true : false : false} type="date" name="fechaNacimiento" id='fechaNacimiento' required defaultValue={deportistaGeneral ? deportistaGeneral.fechaNacimiento : ''} onChange={sacarEdadyGenero}/>
                <label htmlFor="fechaNacimiento">Fecha De Nacimiento</label>
              </div>
              <div className="m-1 form-floating" style={{width: window.innerWidth <= 500 ? '100%' : '120px'}}>
                <select name="genero" id="genero" className='form-select' aria-label="Default select example" required onChange={sacarEdadyGenero}>
                  <option value="">Elige</option>
                  <option value="Femenino">Femenino</option>
                  <option value="Masculino">Masculino</option>
                </select>
                <label htmlFor="genero">Género</label>
              </div>
              <input className='form-control' type="hidden" name="verificacion" id='verificacion' required defaultValue={deportistaGeneral ? deportistaGeneral.verificado : ''}/>
            </div>
            <hr className='m-1'/>
            <div className='d-flex flex-wrap justify-content-around aling-items-center'>
              <Button type='button' icon='pi pi-list' label='Elegir Pruebas' className='btn btn-primary' style={{borderRadius: '10px', width: '200px'}} onClick={() => elegirPruebas()}></Button>
              <div className="alert alert-info alert-dismissible fade show m-0 p-1" role="alert">
                <b>Máximo {parseInt(idTorneoParticipando !== '' ? torneoEspecifico.jornadas[0].cantPruebas : 0)}:</b> <Tag severity={parseInt(idTorneoParticipando !== '' ? torneoEspecifico.jornadas[0].cantPruebas : 0) < pruebasId.length ? 'danger' : 'success'} icon={parseInt(idTorneoParticipando !== '' ? torneoEspecifico.jornadas[0].cantPruebas : 0) < pruebasId.length ? 'pi pi-times-circle' : 'pi pi-check-circle'} value={pruebasId.length + ' Pruebas'}></Tag>
              </div>
            </div>
            <hr className='m-1'/>
            <Mensaje
              anchorId={`nombre`}
              place="bottom"
              content={'Solo se admiten 29 caracteres incluyendo el espacio.'}
              className="bg-primary shadow"
              style={{ zIndex: 1}}
            />
            {mostrar ?(
              mostrarJornadas.length !== 0 ? (
                mostrarJornadas.map((prueba, indexPrueba)=>{
                    return <table key={indexPrueba} className="table table-striped table-hover table-bordered">
                    <thead>
                      <tr>
                        <th colSpan={4}>{`Jornada ${prueba.jornada + 1}`}</th>
                      </tr>
                      <tr>
                        <th colSpan={4}>{acomodarTextoCategoria(mostrarCategorias, listadoCategorias)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pruebasExtras(sacarCantidadPruebas(torneoEspecifico.jornadas[prueba.jornada]), prueba, indexPrueba)}
                    </tbody>
                  </table>
                })
              ):(
                <></>
              )
            ):(
              <></>
            )}
            <Button type='submit' disabled={parseInt(idTorneoParticipando !== '' ? torneoEspecifico.jornadas[0].cantPruebas : 0) < pruebasId.length} icon='pi pi-user-plus' label={`${deportistaGeneral ? deportistaGeneral.records ? 'Modificar' : 'Inscribir' : 'Inscribir'}`} className='btn btn-success mt-1' style={{borderRadius: '10px', width: '200px'}} onClick={() => sacarPruebas()}></Button>
          </form>
            <hr className='m-1'/>
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
              <b>IMPORTANTE:</b> Para asegurar el mejor funcionamiento al calcular la edad, evita hacer copiar y pegar o escribir directamente en el campo de la fecha de nacimiento. En su lugar, te recomendamos buscar directamente la fecha de nacimiento del deportista en el calendario para asegurarte de que la información sea precisa y se ingrese correctamente.
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          </div>
        </Dialog>
        <Dialog visible={dialogDirectivos} style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header={`Registro Directivos`} modal className="p-fluid" onHide={() => setDialogDirectivos(false)}>
          <div className="field">
            <div>
              <div className="alert alert-info alert-dismissible fade show mb-1" role="alert">
                <b>Máximo {parseInt(idTorneoParticipando !== '' ? torneoEspecifico.jornadas[0].cantDirectivos : 0)} Directivos.</b>
              </div>
              <div className="form-floating">
                <input type="text" disabled id='abrev' className='form-control mb-2' defaultValue={usuario ? usuario.abreviatura : ''}/>
                <label htmlFor="abrev">Abreviatura</label>
              </div>
            </div>
            {directivos.map((dire, indexDire) =>{
              return <div key={dire.nombre !== '' ? `${dire.nombre}-${dire.rol}` : indexDire} className='d-flex flex-wrap justify-content-between'>
              <div className="form-floating">
                <input className='form-control mb-2' type="text" maxLength={29} id={`nombreDirectivo${indexDire}`} defaultValue={dire.nombre} />
                <label htmlFor={`nombreDirectivo${indexDire}`}>Nombre Completo</label>
              </div>
              <div className="form-floating">
                <input className='form-control mb-2' type="number" id={`cel${indexDire}`} style={{width: '140px'}} defaultValue={dire.cel}/>
                <label htmlFor={`cel${indexDire}`}>No. Celular</label>
              </div>
              <div className="form-floating mb-2">
                <select id={`rol${indexDire}`} className='form-select' defaultValue={dire.rol}>
                  <option value="">Elige</option>
                  <option value="Entrenador/a Principal">Entrenador/a Principal</option>
                  <option value="Entrenador/a">Entrenador/a</option>
                  <option value="Delegado/a">Delegado/a</option>
                  <option value="Medico">Medico</option>
                  <option value="Fisioterapeuta">Fisioterapeuta</option>
                  <option value="Logistica">Logistica</option>
                </select>
                <label htmlFor={`rol${indexDire}`}>Rol</label>
              </div>
              <div className='d-flex align-items-center'>
                {indexDire === directivos.length - 1 && parseInt(idTorneoParticipando !== '' ? torneoEspecifico.jornadas[0].cantDirectivos : 0) !== directivos.length ? (
                  <Button icon="pi pi-plus" className='me-1' severity="success" raised style={{borderRadius: '50%'}} onClick={() => anadirCamposDirectivo(indexDire)}/>
                ):(
                  <></>
                )}
                <Button icon="pi pi-minus" severity="danger" raised style={{borderRadius: '50%'}} onClick={() => quitarCamposDirectivo(indexDire)}/>
              </div>
            </div>
            })}
            <Button icon="pi pi-check-circle" label='Registrar Directivos' className='btn btn-success' onClick={() => registrarDirectivos()}/>
          </div>
        </Dialog>
        <Dialog visible={pdfUrl ? true : false} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header={`Reporte ${usuario.nombreclub}`} modal className="p-fluid" onHide={() => setPdfUrl()}>
          <div className="field">
            <div className='mb-2 pdf-min pdf-max' style={{width: '100%'}}>
              <embed src={pdfUrl} width='100%' height='100%' type='application/pdf'/>
            </div>
            <div className='d-flex justify-content-between mb-2'>
              <Button icon="pi pi-download" className='p-2' text tooltip='Descargar Reporte' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '10px'}} onClick={() => descargar()}></Button>
            </div>
          </div>
        </Dialog>
        <Dialog visible={dialogRelevos} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header={`Inscripción Relevos`} modal className="p-fluid" onHide={() => setDialogRelevos(false)}>
          <div className="field">
            <motion.div
              className="card m-1 pt-1 col"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1}}
            >
              <form onSubmit={onSubmitRelevo} className='d-flex align-items-center flex-wrap'>
                <div className="card-body w-100">
                  <div className='d-flex flex-wrap justify-content-between mb-1'>
                    <div className="form-floating w-100" style={{ maxWidth: '250px'}}>
                      <select name="jornada" id='jornada' className='form-select' required onChange={(ev) => setElegirRelevos(parseInt(ev.target.value))}>
                        <option value={-1}>Elige la jornada</option>
                        {relevos.length !== 0 ?(
                          relevos.map((_, indexJornada)=>{
                            return <option key={indexJornada} value={indexJornada}>{`Jornada ${indexJornada + 1}`}</option>
                          })
                          ):(
                          <option value="">No hay jornadas</option>
                        )}
                      </select>
                      <label htmlFor="jornada">{`${relevos.length} Jornadas Disponibles`}</label>
                    </div>
                    <div className="form-floating w-100" style={{ maxWidth: '430px'}}>
                      <select name="relevo" id='relevo' className='form-select' required onChange={(ev) => setRelevoElegido(ev.target.value)}>
                        <option value="">Elige el relevo</option>
                        {elegirRelevos !== -1 ?(
                          relevos[elegirRelevos].map((rel)=>{
                            return <option key={rel.id} value={rel.id}>{`${rel.id} | ${rel.prueba.prueba} - ${rel.categoria}`}</option>
                          })
                          ):(
                          <option value="">No hay relevos</option>
                        )}
                      </select>
                      <label htmlFor="relevo">{`${elegirRelevos !== -1 ? relevos[elegirRelevos].length : 0} Relevos Disponibles`}</label>
                    </div>
                  </div>
                  {esRelevoMaster ? (
                    <div className={`alert ${inputsDeportistasRelevos.reduce((acumulador, elemento) => acumulador + elemento.edad, 0) >= relevoEdadInicioFin.edadI && inputsDeportistasRelevos.reduce((acumulador, elemento) => acumulador + elemento.edad, 0) <= relevoEdadInicioFin.edadF ? 'alert-success' : 'alert-danger'} alert-dismissible fade show m-0 mb-1 p-1`} role="alert">
                      <b>Suma Edades: {inputsDeportistasRelevos.reduce((acumulador, elemento) => acumulador + elemento.edad, 0)}</b>
                    </div>
                  ):(
                    <></>
                  )}
                  {inputsDeportistasRelevos.length !== 0 ?(
                    inputsDeportistasRelevos.map((inp, indexInp)=>{
                      return <div key={indexInp} className="p-inputgroup flex-1 mb-1">
                      <Dropdown value={inp} onChange={(e) => agregarDatosAlInputs(e.value, indexInp)} filter options={listaDeportistasRelevo} placeholder={listaDeportistasRelevo.length + ' Deportistas Disponibles'} optionLabel='name'/>
                    </div>
                    })
                  ):(
                    <></>
                  )}
                  <div className='d-flex justify-content-center'>
                    <Button
                      type="submit"
                      icon='pi pi-check'
                      label="Inscribir Relevo"
                      className="btn btn-success"
                      style={{ width: '200px'}}
                      disabled={esRelevoMaster ? !(inputsDeportistasRelevos.reduce((acumulador, elemento) => acumulador + elemento.edad, 0) >= relevoEdadInicioFin.edadI && inputsDeportistasRelevos.reduce((acumulador, elemento) => acumulador + elemento.edad, 0) <= relevoEdadInicioFin.edadF) : false}
                    />
                  </div>
                  <hr className='m-1'/>
                  <div className='table-responsive'>
                    <table className='table table-bordered table-striped table-hover'>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Deportistas</th>
                          <th>Identificación</th>
                          <th>Relevo</th>
                          <th>Genero</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {nadadoresRelevo.length !== 0 ?(
                          nadadoresRelevo.map((rel, indexRel)=>{
                            return <tr key={indexRel}>
                              <td>{indexRel + 1}</td>
                              <td className='dropdown'>
                                <span className='btn btn-secondary btn-sm dropdown-toggle' id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                {rel.equipo[0]}</span>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                  {rel.equipo.map((eq, indexEq)=>{
                                    return <li key={indexEq} className="dropdown-item">{eq}</li>
                                  })}
                                </ul>
                              </td>
                              <td className='dropdown'>
                                <span className='btn btn-secondary btn-sm dropdown-toggle' id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                {rel.identificacion.split(',')[0]}</span>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                  {rel.identificacion.split(',').map((eq, indexEq)=>{
                                    return <li key={indexEq} className="dropdown-item">{eq}</li>
                                  })}
                                </ul>
                              </td>
                              <td>{rel.categoria}</td>
                              <td>{rel.genero}</td>
                              <td><img
                                title='Eliminar'
                                style={{ width: "25px", cursor: "pointer" }}
                                src={require("../img/icono-eliminarFoto.webp")}
                                alt="icono eliminar"
                                onClick={() => eliminarNadadorRelevo(indexRel)}
                              /></td>
                            </tr>
                          })
                        ):(
                          <tr>
                            <td colSpan={6}>No hay relevos inscritos aún.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </motion.div>
          </div>
        </Dialog>
      </motion.div>
      <div className='contactenos'>
        <Conctatenos/>
      </div>
    </>
  );
}

export default InscripcionNadadores;